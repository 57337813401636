

/* ==================== Calendar ==================== */

.calendar_wid_container{
    width: 100%;
}
.calendar_wid_container .calendar_wid_main{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    margin-top: 0px;
    padding-top: 0px;
}
/* .calendar_wid_main svg{
    top: 22px;
} */
.calendar_wid_container .calendar_container{
    position: absolute;
    width: 612px;
    margin-top: 0px;
    top: 80px;
    left: 65%;
    transform: translate(-50%,0);
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--primary_color);
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/9%);
    border: 1px solid #e0e0e0;
    background-color: #fff;
    padding: 0px 0px;

}

.calendar_wid_container .calendar_container.left{
    left: 0 !important;
}

.calendar_container .calendar_main{
    padding: 0px 0px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
  position: absolute;
  cursor: pointer;
  left: 25px;
  top: 2px;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: 2px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 15px;
    width: 100%;
    /* border-bottom: 1px solid #ddd; */
    background: var(--primary_color);
    padding-top: 15px;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 10px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 7px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 35px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #dbdbdc; */
    /* margin: 2px; */
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 35px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #cbe3f5; */
    background-color: var(--primary_color);
    opacity: 0.5;
    color: #fff;
    border-radius: 50px;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.calendar_body{
    padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
    /* border-radius: 5px; */
    color: #fff;
    background-color: var(--primary_color);
    border-radius: 50px;
}

.calendar_head_icon svg{
    position: relative !important;
    top: unset !important;
    right: unset !important;
    color: #fff !important;
    top: 7px !important;
    width: 30px;
    height: 30px;
}

.child_row_main{
    position: relative;
    /* width: 25%; */
    width: 30%;
}
.child_age_select_arrow svg{
    height: 12px;
    width: 12px;
    position: relative;
    left: unset;
    top: unset;
    color: var(--secondary_color);
    fill: var(--font_color);
    right: 2px;
    top: 2px;
}

.child_row_main .child_age_select_arrow{
  position: absolute;
  right: 5px;
  top: 10px;

}
.container_tooltip{
    position: absolute;
    margin-top: -60px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    white-space: nowrap;
    font-size: 11px;
    pointer-events: none;
    z-index: 99;
}
.container_tooltip:before {
	position: absolute;
	bottom: -5px;
	left: calc(50% - 5px);
	border-top: 5px solid rgba(0, 0, 0, 0.12);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	content: ""
}
.container_tooltip:after {
	position: absolute;
	bottom: -4px;
	left: calc(50% - 4px);
	border-top: 4px solid #fff;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: ""
}
.child_row_container{
    display: flex;
    /* justify-content: space-between; */
    gap: 3%;
    align-items: center;
    flex-wrap: wrap;
    /* width: 72%; */
    width: 100%;
    justify-content: flex-end;
    margin-left: auto;
}

.child_row_container .child_row_main .err_message_text{
  font-size: 11px;
  color: #e8664e;
  line-height: 13px;
  margin-top: 10px;
  position: absolute;
  left: -25px;
  white-space: nowrap;
  top: 88px;
}

/* calendar css ends  */

.activity_search_wid_container{
    width: 100%;
    /* padding: 30px 25px 50px 30px; */
    padding: 20px;
    display: table;
    background: #fff;
    margin-bottom: 105px;
    border-radius: 5px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .15);
    margin-top: 0px;
    position: relative;
}
.activity_way_title{
    width: 100%;
    text-align: left;
}

.activity_way_title h2{
    font-family: var(--font_family);
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    text-align: left;
    color: var(--primary_color);
}
.activity_search_wid_inputfield input{
    padding-top: 13px !important;;
}
.activity_search_details_container{
    margin-top: 0px;
    width: 100%;
   
    /* padding: 11px 20px; */
    /* border-radius: 30px; */
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row{
    display: flex;
    /* flex-wrap: wrap;
    justify-content: space-between; */
}

.activity_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column{
    float: left;
    margin-right: 0px;
    margin-bottom: 0px;
    position: relative;
    background: #fff;
    padding: 0;
    padding-top: 17px;
    padding-bottom: 11px;
    border-bottom:2px solid #dedede ;
}
.activity_search_details_row .activity_search_column.activity_search_date{
    width: 40%!important;
    /* border-left: 1px solid 
    #dedddd; */
    border-radius: 0 !important;
    padding-left: 0px !important;
}
.activity_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
}
.activity_search_wid_inputfield .activity_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}
.activity_search_details_main .activity_search_details_row .activity_search_name{
    width: 44%;
    /* border-radius: 10px; */
    margin-right: 43px!important;
}
.activity_search_details_main .activity_search_details_row .activity_search_date{
    width: 42.1%;
}
.activity_search_details_main .activity_search_details_row .activity_search_accomodation{
    width: 22%;
}
.activity_search_widget_btn:hover, .activity_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  /* box-shadow: 1px 1px 10px var(--buttons_color); */
    transition: transform .3s ease;
}
.activity_search_details_row .activity_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 40px;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .4px;
    text-align: center;
    margin-left: 0;
    color: #fff;
    cursor: pointer;
    outline: 0;
}
.activity_nw .activity_search_widget_btn{
    margin-top: 0;
    width: 200px;
    /* height: 3.3rem; */
    /* height: 4rem; */
}
.activity_search_details_row .activity_search_class{
    margin-left: auto;
    order: 2;
    /* margin-right: 3px; */
    padding-left: 0;
    /* width: 17%; */
    padding-top: 0!important;
    align-self: flex-end;
}

.activity_search_accomodation .activity_cabin_class_cont{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.activity_search_column .activity_room_list{
    position: absolute;
    min-width: 265px;
    width: 100%;
    top: 60px;
    background-color: #ffffff;
    left: 0px;
    z-index: 99;
    box-sizing: border-box;
    max-height: 400px;
    box-shadow: 0px 0px 3px 0px #aaa;
    overflow: auto;
}

.activity_search_column .activity_room_list .activity_room_list_main{
    width: 360px;
    width: 100%;
    background-color: #ffffff;
    text-align: left;
    border-top: none;
    box-shadow: 0 0 18px 2px rgb(0 0 0 / 7%);
}

.activity_search_column .activity_room_list .activity_room_list_main .activity_room_detail_ele{
    text-align: left;
    padding: 10px 10px;
    width: 100%;
}

.activity_room_detail_ele .activity_room_title{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
    text-transform: uppercase;
}
.activity_room_head{    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.activity_room_remove{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    color: var(--font_color);
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
}
.activity_search_details_container .activity_search_details_row .activity_search_column .activity_search_wid_inputfield .activity_search_wid_inputfield_main .activity_search_error_message{
    position: relative;
    top: -10px;
    float: left;
    width: 104%;
}
.activity_search_details_container .activity_search_details_row .activity_search_column .activity_search_wid_inputfield .activity_search_wid_inputfield_main .activity_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.activity_accomodation_main >div{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.activity_accomodation_main >div img{
    position: relative;
    top: unset;
    left: unset;
}

.activity_accomodation_conf{
    display: flex;
    align-items: center;
}

.activity_accomodation_conf div{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    width: 50px;
    text-align: center;
}
.activity_accomodation_main span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
}

.activity_accomodation_main p{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    margin: 0px 0px;
    display: block;
}
.activity_room_detail_ele .activity_room_title span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
}

.activity_room_list_main .activity_room_detail_ele .activity_accomodation_main{
    display: block;
    margin-top: 0px;
    position: relative;
    width: 100%;
}

.activity_accomodation_conf .activity_accomodation_icon svg{
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: snow;
    border: 0;
    position: relative;
    top: unset;
    left: unset;
}

.age_list_ele{
    cursor: pointer;
    width: 100%;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    padding: 0 15px;
}

.disbale_traveller{
    pointer-events: none;
    cursor: default;
}

.disbale_traveller img{
    opacity: 0.5;
}

.done_button_main{
    width: 100%;
    text-align: left;
    padding-top: 25px;
    background: #fff;
}

.done_button_main .done_button{
    background-color: var(--buttons_color);
    border: none;
    color: #ffffff;
    padding: 11px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
}

.activity_search_details_row label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-bottom: 0px;
    padding-left: 0px;
    /* text-transform: uppercase; */
}

.activity_search_details_row >div{
    padding-left: 15px;
    padding-top: 15px;
}
.activity_nw{
    padding-left: 10px !important;
    padding-top: 0 !important;
}
.activity_search_details_row .activity_search_date  svg{
    top: 50px;
}


.activity_search_details_row svg{
    position: absolute;
    left: 6px;
    top: 48px;
    width: 22px;
    height: 22px;
    color: var(--button_color);
}
.activity_search_class button{
    position: relative;
}
.activity_search_class button svg{
    position: absolute;
    left: 45px;
    top: 29px;
    width: 18px;
    height: 22px;
    color: var(--button_color);
}
.activity_search_details_row input,.activity_cabin_class_name
{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    border: 0 !important;
    padding: 0px 0px 0 0px;
    background-color: transparent;
    outline: none !important;
    margin-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 98%;
    cursor: pointer;
    text-shadow: 0 0 0 var(--font_color);
    padding-top: 10px;
    padding-left: 32px;
    height: auto;
}

.activity_search_details_row input:focus{
    box-shadow: none;
}

.activity_search_details_row input::placeholder{
    font-size: 14px;
    color: var(--primary_color);
    font-weight: normal;
}
.activity_search_wid_inputfield_main input::placeholder{
    font-size: 14px;
    color: var(--primary_color);
    font-weight: bold;
}
.activity_autocompWrap{
    width: 325px;
    padding: 0px 0px 0px 0px;
    border-radius: 5px;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d4d4d4;
    background-color: #fff;
    position: absolute;
    top: 50px;
    overflow: hidden;
    max-height: 245px;
    z-index: 9;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
}

.activity_autocomplete_value{
    position: absolute;
    width: 310px;
    position: relative;
    top: 0 !important;
    max-height: 245px;
    border-radius: 0;
    z-index: 9;
    top: 50px;
    background-color: #fff;
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
    /* border-radius: 10px; */
    box-shadow: none;
    background-color: #fff;
    padding-left: 20px;
    padding-top: 17px;
}
.activity_recentsearch{
    font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  text-align: left;
  color: #606060;
  margin-left: 20px;
}
    .activity_autocomplete_element{
            width: 100%;
            display: flex;
            padding: 5px 9px;  
            cursor: pointer;      
            align-items: flex-start;
            min-height: 40px;
            position: relative;
    }

    .activity_autocomplete_element:hover,
    .activity_autocomplete_element:focus{
        background: var(--primary_color);
        border-radius: 50px;
        padding-top: 8px;
        
    }
    .activity_autocomplete_element:hover  .activity_autocomplete_name,
    .activity_autocomplete_element:focus .activity_autocomplete_name{
        color:#fff;
    }
    .activity_autocomplete_element:hover  svg {
        filter: invert(168%);
    }



.activity_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.activity_autocomplete_tag{
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}


.activity_search_details_row ::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  .activity_search_details_row ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .activity_search_details_row ::-webkit-scrollbar-thumb {
    background: 
    #e4e4e4;
    border-radius: 10px;
  }
  .activity_autonwLabel{
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: #979797;
    padding-top: 3px;
  }
  .activity_suggestionLbl{
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: #979797;
    padding: 13px 9px;
    display: none;
  }

  .activity_autocomplete_element img{
      position: relative;
      left: unset;
      top: unset;
      height: 23px;
      width: 23px;
      object-fit: scale-down;
      margin-right: 15px;
  }


.activity_add_rooms {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    display: flex;
    padding: 15px 10px;
    cursor: pointer;
}

.activity_add_rooms span{

    width: 23px;
    height: 23px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 23px;
    margin: -3px 10px 0 0;
    font-weight: normal;
}

.child_row_main label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 15px 0px 10px 0px;
    width: 100%;
    display: block;
}

.child_row_main input{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    width: 100%;
    height: 35px;
    padding-bottom: 5px;
    border-bottom: 1px#dcd8d8 solid !important;
    /* background: url(../image/down_arrow.jpg) center right no-repeat; */
    background-position: 97% 10px;
    cursor: pointer;
}

.child_row_main input::placeholder{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

/* ==================== Calendar ==================== */

.calendar_wid_
.child_row_main{
    position: relative;
}
.child_age_select_arrow svg{
height: 15px;
width: 15px;
position: relative;
left: unset;
top: unset;
}

.child_row_main .child_age_select_arrow{
    position: absolute;
    right: 0;
    top: 43px;

}
.activity_search_wid_inputfield span img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
}
.activity_search_wid_inputfield span small {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--primary_color); 
    mask: url('/images/location-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/location-01.svg') no-repeat center / contain;

}
.activity_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column.activity_search_date span small{
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--primary_color); 
    mask: url('/images/calendar-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/calendar-01.svg') no-repeat center / contain;
}
.activity_autocomplete_element >span{
    width: 19px;
    height: 19px;
    background-color: #000000; 
    mask: url('/images/location-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/location-01.svg') no-repeat center / contain;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 15px 0px 0px;
}
.activity_autocompIcon svg{
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
    margin-right: 11px;
}
.calendar_wid_container img {
    position: absolute;
    left: -2px;
    top: 46px;
    width: 28px;
    height: auto;
    /* color: var(--primary_color); */
}
.error{
    font-size: 13px;
    color: #e8664e;
    line-height: normal;
   margin-top:0px;
}
.activity_search_details_row .errordate{
    padding-left: 32px;
    position: absolute;
    bottom: -17px;
}
.errordate{
    font-size: 11px;
    color: #e8664e;
    line-height: normal;
    margin-top:0px;
}
.paxSelect{
    display: block;
}
.table_subhead{
    font-size: 10px;
  }
  .table_head, .table_body{
    width: 100%;
  }
  .table_head thead tr th{
    padding: 10px;
    background: var(--primary_color);
    color: #fff;
    text-align: center;
  }
  .table_body tbody tr td{
    padding: 10px;
    text-align: center;
  }
  .hotel_room_detail_ele{
    text-align: left;
    /* padding: 10px;
    width: 100%; */
    margin: 10px 10px;
    padding-bottom: 5px;
    /* border-bottom: 1px solid #eaeaea; */
  }
.activity_search_new{
    margin: 10px 17px;
    border: 1px solid #b4b4c8;
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
}
.activity_citysearch{
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  text-align: left;
  color: #606060;
  padding-left: 8px;
}
.activity_iconsearch{
    margin-top: 2px;
}
.activity_iconsearch svg{
    position: unset;
}
.activity_autocomplete_newname{
    font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #959595;
}
.activity_search_details_row .input_search_destination svg {
    position: absolute;
    left: 7px;
    top: 23px;
    width: 20px;
    height: 19px;
    color: var(--button_color);
}
.input_search_destination input {
    padding: 10px 25px;
    margin: 10px 0;
    border: 1px solid #d4d4d4!important;
    display: block;
    width: 324px !important;
    height: 41px;
    border-radius: 5px;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    font-weight: normal;
    font-size: 15px;
    color: #979797;
}
.input_search_destination input::placeholder{
    font-family: var(--font_family);
    font-size: 15px !important;
    font-weight: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: #979797 !important;
}

.activity_autocompContainer{
    position: absolute;
}
.activity_RecentLbl{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--secondary_color);
    padding: 19px 9px;
    padding-left: 38px;
    padding-bottom: 15px;
}

.date_dropdown_icon{
    position: absolute;
    top: 23px;
    left: 123px;
    display: none;
}
.date_dropdown_icon svg{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.activity_autocomplete_noresult{
    display: flex;
    align-items: center;
    padding: 5px 9px;
    padding-bottom: 15px;
}
.noresult_icon{
    padding: 0;
}
.noresult_icon svg{
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
    margin-right: 11px;
}
.activity_autocomplete_noresult{
    font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}
.parentWidFour {
    position: relative;
    z-index: 9;
    background: #edf7fd;
    padding: 30px 0;
    margin-top: 60px;
}
#srp-activities-content{
    background-color: #edf7fd;
}
.srp_search_wid_container {
    width: 100%;
    padding: 0;
    display: table;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
    background-color: #def1fa;
    margin: 10px 0px;
    border-radius: 0px;
    padding-bottom: 0;}
    
  .srp_search_widget_btn{
    text-transform: capitalize;
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 15px;
    width: 111px;
    height: 32px;
    margin: 0;
    display: block;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
  }
  .srp_search_details_container {
    margin-top: 0;
    width: 100%;
    border: 0;
    padding: 11px 20px;
    border-radius: 0;
    padding-bottom: 28px;
  }

.srp_search_details_container .activity_search_details_main .activity_search_details_row{
    display: flex;
    /* flex-wrap: wrap;
    justify-content: space-between; */
}


.srp_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column{
    float: left;
    margin-right: 0px;
    margin-bottom: 0px;
    position: relative;
    /* background: #fff; */
    padding: 0;
    padding-top: 17px;
    padding-bottom: 11px;
    padding: 17px 0 7px;
    border-bottom: 1px solid #b9d4e1;
}

span.calendar_head_icon svg {
    width: 25px !important;
    height: 25px !important;
    position: absolute !important;
    top: 10px !important;
    left: 0px;
    right: 0 !important;
    bottom: 0;
}
.activity_autocomplete_noresultnw{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
}
.flex_calwp{
    display: flex;
    align-items: center;
}

.activity_search_details_row input::placeholder{
    font-size: 14px;
    font-weight: bold;
    color: var(--primary_color);
}

.srp_search_widget {
    display: inline;
}
.pax_start_div{
    position: absolute;
    width: 370px;
    top: 60px;
    left: 0;
    background-color: #fff;
    right: 0;
    z-index: 9;
    box-sizing: border-box;
    max-height: 400px;
    overflow: visible;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}
.passenger_wrapper{
    width: 33.33%;
}
.passenger_counter{
position: relative;
}

.passenger_counter_input{

}
.paxSelect_container{
    padding-top: 17px !important;
    padding-bottom: 11px;
    border-bottom: 2px solid #dedede;
    margin-left: 43px;
}
.pax_start_new{
    display: flex;
}
.button_wrapper{
    padding: 15px 15px;
    padding-top: 5px;
    text-align: center;
}
.passenger_icon_main{}
.passenger_icon svg{
    top: 30px;
}

.activity_search_details_row_offline .activity_search_column {
    width: 33% !important;
}

.activity_search_details_row_offline .paxSelect_container {
    width: 33% !important;
    padding-left: 0;
}
.activity_search_details_row_offline .activity_search_class{
    margin-left: 20px !important;

}

/* Enquiry popup */
.enquiy_container{
    width: 100%;
    text-align: center;
    padding: 27px 0;
  }
  .send_success{
    text-align: center;
    margin-bottom: 35px;
  
  }
  .send_success img{
    width: auto;
    height: 51px;
    margin: 0 auto;
    margin-bottom: 18px;
  }
  .send_lbl{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--primary_color);
  }
  
  .quote_lblTitle{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--font_color);
  }
  
  .quote_success{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--primary_color);
  }
  .enquire_wrap{
    margin-bottom: 23px;
  }
  
  .ok_button{
    padding: 0 28px;
    height: 37px;
    border-radius: 30px;
    background-color: var(--buttons_color);
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
  }
  
  .btn_container{
    margin-top: 28px;
  }
  /* Enquiry popup */

  .activity_offline_enqCOnt svg{
    position: absolute;
    left: 6px;
    top: 48px;
    width: 22px;
    height: 22px;
    color: var(--button_color);
  }
  .activity_offline_enqCOnt input{
    padding-left: 24px !important;
}

.activity_offline_enqCOnt :global(.theme4_vt_country_select_container__vp3GT) {
    margin-top: 0;
    padding: 0;
    height: auto;
    border: 0;
  }
  
  .activity_offline_enqCOnt :global(.theme4_vt_country_select_main_container__SWTdQ .theme4_vt_country_select_list_container__TlUZ9) {
    overflow-y: auto;
    top: auto;
    left: 0;
    border-radius: 5px !important;
  }
  .activity_offline_enqCOnt :global(.theme4_signup_cont__VspMU) {
    width: 100%;
  }

@media only screen and (max-width: 768px) and (min-width: 320px) {
    .activity_search_details_row .errordate{
        bottom: -16px;
        padding-left: 0;
    }
    .activity_offline_enqCOnt{
        position: relative;
        border: 1px solid #dedede;
        background: #fff;
        border-radius: 8.7px !important;
        height: 67px;
        padding: 12px 9px !important;
    
    }
    .activity_offline_enqCOnt input{
        padding-left: 20px !important;
    }
    .activity_offline_enqCOnt :global(.theme4_vt_country_select_main_container__ua2YN .theme4_vt_country_select_list_container__Tr7KY) {
        left: 0;
    }
  
    .activity_search_details_row .activity_search_date label{
        padding-bottom: 3px;
    }
    .activity_search_wid_container {
        margin: 0px 8px 8px 8px;
        padding: 36px 9px 35px;
        box-shadow: 0px 3px 13px 0 rgba(0, 0, 0, 0.1);
        background-color: #266aac;
        border-radius: 7px;
        width: auto;
        display: block;
    }
    
    .activity_search_details_container {
        margin-top: 0px;
        width: 100%;
        border: 1px solid transparent;
        padding: 0;
        border-radius: 0;
    }
    
    .activity_search_details_container .activity_search_details_main .activity_search_details_row {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    
    .activity_search_details_main .activity_search_details_row .activity_search_name {
        width: 100%;
        border-radius: 8.7px;
        height: 67px;
        padding: 12px 9px !important;
    }
    .activity_search_details_row label{
        font-size: 13px;
        padding-bottom: 6px;
        display: block;
    }
    .activity_search_details_row input{
        padding: 0;
    font-size: 15px;
    font-weight: 600;
    padding-top: 3px !important;
    padding-left: 30px;
    }
    .activity_search_details_row input::placeholder{
        font-size: 14px;
    font-weight: bold; 
    }
    .search_container input{
        font-size: 13px;
        font-weight: normal;
        height: 37px;
        width: 100%;
        padding: 12px 12px 12px 6px;
        border-radius: 4.3px;
        background-color: #ebebeb;
        padding-left: 22px;
        letter-spacing: 0.33px;
        text-align: left;
        color: #979797;
    }
    .search_container input::placeholder{
        color: #979797;
        font-weight: normal;
    }
    .error{
        font-size: 11px;
        color: #e8664e;
        line-height: 8px;
        margin-top: 0px;
    }
    
    .activity_search_details_row .activity_search_column.activity_search_date {
        width: 100%!important;
        border-left: 1px solid #dedddd;
        border-radius: 8.7px !important;
        padding-left: 32px!important;
        height: 67px;
        padding: 12px 9px!important;
    }
    
    .activity_search_details_row .activity_search_class {
        margin-left: unset;
        order: 2;
        padding-left: 0;
        padding-top: 0 !important;
        align-self: unset;
        width: 100%;
    }
    
    .activity_search_details_row .activity_search_widget_btn {
        background-color: var(--secondary_color);
        border: 1px solid var(--secondary_color);
        border-radius: 50px;
        width: 100%;
        height: 50px;
        font-family: var(--font_family);
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: .5px;
        text-align: center;
        margin-top: 3px;
        margin-left: 0;
        color: #fff;
        cursor: pointer;
        outline: 0;
        position: relative;
        text-transform: none;
    }
    /* calendar design css */
    .calendar_wid_container {
        width: 100%;
        display: flex;
    }
    .activity_search_details_row svg {
        position: absolute;
        left: 12px;
        top: 34px !important;
        width: auto;
        height: auto;
        color: var(--button_color);
        margin-right: 0px;
    }
    .calendar_wid_container .calendar_wid_main {

        line-height: normal;
        letter-spacing: normal;
        width: 100%;
    }
    .calendar_wid_container .calendar_container {
        position: absolute;
        width: 625px;
        margin-top: 0;
        top: 50px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 9;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--font_color);
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
        border: solid 1px #d4d4d4;
        background-color: #fff;
        padding: 0;
    }
    .calendar_container .calendar_main {
        padding: 10px 15px;
        display: flex;
        flex-wrap: nowrap;
        padding-top: 15px;
    }
    .calendar_main .calendar_whole_body {
        width: 100%;
        box-sizing: content-box;
    }
    .calendar_main .calendar_head {
        display: flex;
        width: 100%;
    }
    .calendar_head .calendar_head_center_side {
        font-size: 14px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        text-align: center;
        text-transform: capitalize;
        color: var(--font_color);
        padding-bottom: 15px;
        width: 100%;
    }
    .calendar_head .calendar_head_icon {
        position: relative;
        left: 0;
        top: 0;
    }
    .calendar_body .calendar_week_heads {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }
    .calendar_week_heads .coln_center_week {
        padding: 5px 0;
        font-size: 12px;
        font-weight: 400;
        width: calc(100% / 7);
        text-align: center;
        font-family: var(--font_family);
        text-transform: capitalize;
        color: var(--font_color);
    }
    .calendar_body .calendar_day_list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .calendar_body .calendar_day_list .day_cell_center {
        text-align: center;
        text-decoration: none;
        border-radius: 3px;
        transition: color 0.3s, border 0.3s;
        cursor: pointer;
        height: 44px;
        font-family: var(--font_family);
        font-size: 14px;
        width: calc(100% / 7);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .calendar_body .calendar_day_list .day_cell_center_highlight {
        text-align: center;
        text-decoration: none;
        transition: color 0.3s, border 0.3s;
        cursor: pointer;
        height: 44px;
        font-family: var(--font_family);
        font-size: 14px;
        width: calc(100% / 7);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #d6e4f4;
        color: #fff;
    }
    .calendar_body .calendar_day_list .day_cell_center > span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    .calendar_body {
        padding: 0 10px;
    }
    .calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
    .calendar_body .calendar_day_list .day_cell_center:hover > span.calendar_day_disable .calendar_body .calendar_day_list .day_cell_center:focus > span.calendar_day_disable {
        cursor: not-allowed;
        color: #999 !important;
        background-color: transparent !important;
        opacity: 0.5;
    }
    .calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
    .calendar_body .calendar_day_list .day_cell_center:focus > span,
    .calendar_body .calendar_day_list .day_cell_center:hover > span {
        border-radius: 5px;
        color: #fff;
        background-color: var(--primary_color);
    }
    .calendar_body .calendar_day_list .day_cell_center.selected_calendar_date > span {
        color: #fff;
        font-family: var(--font_family);
    }
    .calendar_head_icon svg {
        position: relative !important;
        top: unset !important;
        right: unset !important;
        color: var(--secondary_color) !important;
    }
    .filter_container {
        display: flex;
        gap: 10px 40px;
        flex-wrap: wrap;
    }
    .calendar_head_icon svg {
        width: 25px;
    }
    .calendar_head_center_side h3 {
        font-family: var(--font_family);
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--secondary_color);
    }
    .calendar_head_center_side h3 span {
        font-family: var(--font_family);
        font-size: 20px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--secondary_color);
    }

        .calendar_wid_container .calendar_wid_main {
            padding: 0;
        }
        .calendar_wid_container {
            display: block;
        }
        .calendar_wid_container_popup {
            z-index: 100;
            position: fixed;
            min-width: 333px;
            height: 100%;
            background: #fff;
            overflow: auto;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        .calendar_wid_container_popup .calendar_head_container {
            width: 100%;
            top: 0;
            position: sticky;
            left: 0;
            z-index: 2;
            background-color: #fff;
        }
        .calendar_wid_container_popup .calendar_head_container .calendar_head_main {
            width: 100%;
            display: flex;
            color: var(--font_color);
            padding: 20px;
            border-bottom: 1px solid #ddd;
            font-size: 14px;
        }
        .calendar_head_main span {
            display: block;
        }
        .calendar_wid_container_popup .calendar_head_container .calendar_head_main h5 {
            border-bottom: 2px dashed #ccc;
            display: inline-block;
            font-weight: 700;
        }
        .calendar_wid_container .calendar_main_body_popup {
            overflow: auto;
            padding: 20px 15px 70px;
            width: 100%;
            display: block;
        }
        .calendar_week_heads .coln_center_week {
            padding: 5px 0;
            font-size: 13px;
            width: calc(100% / 7);
            text-align: center;
            font-family: var(--font_family);
            text-transform: uppercase;
            color: #8a8a8a;
        }
        .calendar_week_heads {
            display: flex;
            padding: 0 15px;
            padding-top: 10px;
            padding-bottom: 6px;
            border-bottom: 1px solid #efeff1;
            background-color: #fff;
        }
        .calendar_body {
            padding: 0;
            padding-bottom: 10px;
            margin-bottom: 25px;
        }
        .calendar_footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 999;
            background: #fff;
            padding: 30px 10px;
            text-align: center;
            box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
        }
        .calendar_footer button {
            width: 100%;
            height: 57px;
            padding: 21px 20px;
            border-radius: 10px;
            background-color: var(--buttons_color);
            font-family: var(--font_family);
            font-size: 18px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
        }
        .calendar_wid_main input::placeholder{
            color: var(--secondary_color);
            font-weight: normal;
        }
        .calendar_wid_main input {
            /* height: 13px; */
            font-family: var(--font_family);
            font-size: 15px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.38px;
            text-align: left;
            color: var(--secondary_color);
            background: 0 0;
            padding: 0;
        }
        .calendar_wid_main input::placeholder{
            font-size: 13px;
            font-weight: bold; 
        }
        .modal_header {
            padding: 0 15px;
            background: var(--primary_color);
            height: 65px;
            width: 100%;
            z-index: 9999;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .modal_header svg {
            width: 20px;
            height: max-content;
            position: absolute;
            top: 20px !important;
            object-fit: contain;
            left: 15px;
        }
        .header_text {
            font-size: 20px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: left;
            color: #fff;
        }
        .search_container {
            position: relative;
            background-color: #1b5d9d;
            height: 57px;
            padding: 10px 16px 10px 16px;
        }
        .search_icon svg {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 58% !important;
            left: 22px;
            transform: translateY(-58%);
        }
        .date_header_container {
            position: sticky;
            top: 0;
            z-index: 99;
            background: #fff;
        }
        .calendar_body .calendar_day_list .day_cell_center {
            font-size: 16px;
            color: var(--secondary_color);
            height: 50px;
        }
        .calendar_week_heads .coln_center_week:nth-last-child(-n + 2) {
            color: var(--primary_color);
        }
        .calendar_body .calendar_day_list .day_cell_center_highlight {
            color: #000;
            font-weight: 700;
            background-color: transparent;
        }
        .calendar_body .calendar_day_list .day_cell_center.selected_calendar_date > span {
            font-weight: 700;
            background: var(--primary_color);
            width: 40px;
            height: 44px;
            border-radius: 5px;
        }
        .day_cell_center_highlight span {
            background: #b9d1e9;
            width: 40px;
            height: 44px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .calendar_body .calendar_day_list .day_cell_center.selected_calendar_date {
            background-color: transparent;
        }
    
    /* calendar design css */
    .activity_autocomplete_value {
        width: 100%;
        position: absolute;
        top: 68px!important;
        border-radius: 0;
        overflow: auto;
        box-shadow: none;
        /* min-height: 100vh; */
        border: 1px solid #d4d4d4;
        border-radius: 10px;
        /* padding-left: 10px; */
        padding-left: 10px;
    padding-top: 0px;
    }
    .activity_search_details_row .activity_autocompIcon svg {
        top: 10px !important;
    }
    
    .input_search_destination {
        padding: 10px 17px 10px 16px;
        background-color: #1b5d9d;
    }
    
    .activity_search_details_row .input_search_destination svg {
        position: absolute;
        left: 21px;
        top: 21px !important;
        width: 20px;
        height: 19px;
        color: var(--button_color);
    }
    
    .input_search_destination input {
        width: 100% !important;
        margin: 0;
        padding-left: 22px;
        height: 37px;
        background-color: #ebebeb;
        border-radius: 4.3px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.33px;
        text-align: left;
        color: #979797;
    }
    .input_search_destination input::placeholder{
        font-weight: normal;
    }
   
    .activity_autocomplete_element{
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 5px 15px;
        align-items: center;
    }
    .activity_RecentLbl {
    padding-left: 15px;
    padding-bottom: 10px;
     font-size: 15px;
  font-weight: bold;
}
.activity_autocomplete_name{
     font-size: 14px;
  font-weight: normal;
  padding-left: 23px;
}
  .activity_autonwLabel{
    font-size: 13px;
    font-weight: normal;
  }
    .activity_suggestionLbl {
    padding: 12px 15px;
    padding-top: 15px;
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: #000;
}
.activity_autocompIcon svg{
    margin-right: 0;
}
.activity_autocomplete_noresultnw {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color:#979797;
}  

.activity_search_wid_container1 .activity_search_wid_main{
    padding-bottom: 0px;
}
/* mobile header css  */
/* .activity_search_wid_container1 .activity_search_wid_main{
    padding: 10px 0px;
    padding-top: 0;
} */
 /* .landing_tab_submenu_new{
    position: sticky;
    top: 0;
    z-index: 99;
 } */
 #tab_submenu_content{
    position: sticky;
    top: 0;
    z-index: 99;
 }

.activity_search_wid_container1 .activity_search_wid_main .mobile_visible{
    display: block;
}

.activity_search_wid_container1 .activity_search_wid_main .mobile_visible .mobo_tab_header{
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
    /* justify-content: space-around; */
    padding: 0px 15px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
}
.activity_search_wid_container1 .activity_search_wid_main .mobile_visible .arrow_image{
    width: 20px;
    height: 24px;
    margin-right: 10px;
}

.activity_search_wid_container1 .activity_search_wid_main .mobile_visible .search_filter_header{
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
   color: var(--primary_color);
}

/* mobile header css ends   */
    .activity_search_wid_container1{
        background: #e7e9eb;
    min-height: 100vh;
    }

.activity_search_details_container{
    width: auto;
    background: 
    #e7e9eb;
    border-radius: 0;
    padding: 10px;
    padding-bottom: 0px;
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row{
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    /* padding: 15px;
    background: #fff;
    border-radius: 15px; */
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row .mob_hotel_search_column{
    float: left;
    margin-right: 0;
    margin-bottom: 3px;
    position: relative;
    background: #ffff;
    border-radius: 10px;
    border: solid 1px #d4d4d4;
    /* height: 65px; */
    margin-bottom: 12px;
    width: 100%;
}
.activity_search_details_main{
    padding: 15px;
    background: #fff;
    border-radius: 15px;
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column{
    border:1px solid #dedede ;
    margin-right: 0 !important;
    margin-bottom: 30px;

}

.activity_search_details_container .activity_search_details_main .activity_search_details_row_offline .activity_search_column{
   margin-right: 0 !important;
}

.activity_search_details_row_offline .activity_search_column {
    width: 100% !important;
}

.activity_search_details_row_offline .paxSelect_container {
    width: 100% !important;
    padding-left: 0;
    margin-left: 0 !important;
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 12px 9px;
    height: 67px;
}
.activity_search_details_row_offline .activity_search_class{
    margin-left: 0px !important;

}
.offline_activity{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 10px;
    margin-top: 0;
    display: block;
    width: auto;
    background-color: #fff;
}
.offline_activity .activity_search_details_main{
    padding: 0;
    margin-top: 15px;
}
.activity_search_details_row_offline .passenger_icon_main .passenger_icon svg {
    width: 23px;
    left: 0px;
    top: 20px !important;
}

.offline_activity .activity_search_details_row >div{
    margin-bottom: 5px !important;
}
.offline_activity .errordate{
    bottom: -15px;
}
.offline_activity{}


}

.arrow_rotate {
    top: 3px !important;
    transform: rotate(180deg);
    transition: all 0.2s linear;
  }

  .hotel_accomodation_conf{
    display: flex;
    align-items: center;
    background: #fff;
    /* box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(204, 204, 204, 0.46); */
    width: max-content;
    padding: 4px 5px;
    border-radius: 20px;
    float: right;
  }
  
  .hotel_accomodation_conf div{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #000;
    width: 35px;
    text-align: center;
  }
  .hotel_accomodation_conf svg{
    position: initial !important;
  }
  .hotel_accomodation_icon svg{
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    cursor: pointer;
    /* background-color: snow;
    border: 0; */
    border: 1px solid var(--secondary_color);
    border-radius: 50px;
    position: relative;
    top: unset;
    left: unset;
  }
  .pax_start_div{
    display: flex;
    flex-direction: column;
  }
  .paxSelect {
    display: block;
    margin-right: 0px;
    margin-bottom: 0px;
    position: relative;
    background: #fff;
    padding: 0;
    padding-top: 17px;
    padding-bottom: 11px;
    border-bottom: 2px solid #dedede;
}
.passenger_counter_input {}
.btn_done {
    padding: 0 28px;
    height: 37px;
    border-radius: 30px;
    background-color: var(--buttons_color);
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
}
.activity_search_details_row_offline {
    outline: 0;
}
.flight_way_btngrp{
    position: relative;
  }
  .trip_type_container {
    width: 100%;
    margin-bottom: 8px;
}
.trip_type_container span {
    margin-right: 30px;
    color: var(--font_color);
}
.trip_type_heading {
    color: #000;
}
.trip_type_container span input {
    margin-right: 12px;
}

@media only screen and (max-width: 767px) and (min-width: 320px){
    .trip_type_container span {
        max-width: 123px;
        display: inline-block;
    }
    
    .trip_type_container span {
        margin-right: 5px;
    }
    .trip_type_container span input {
        margin-right: 3px;
    }
}