.nationlity {
  width: 100%;
}
/* .traveller_button{
    background-color: var(--primary_color);
    width: 20px;
    border-radius: 5px;
    color: #fff;
} */
.traveller_button {
  width: 40px;
  padding: 1px 10px 1px;
  border-radius: 5px;
  background-color: var(--primary_color);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.guesttext {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: left;
  color: var(--font_color);
}
.dropdown_Guest_container {
  width: 600px;
  /* width: auto; */
  min-height: 204px;
  margin-top: 10px;
  padding: 15px;
  border: solid 2px #ccc;
  border-radius: 5px;
  background-color: #e2e2e2;
  position: absolute;
  right: 0;
  margin-left: auto;
  z-index: 9;
}
.inputsearch input {
  width: 100%;
  height: 36px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.adv_search_container,
.addguestbtn {
  position: relative;
  width: max-content;
  margin-left: auto;
}
.trav_flex{
  display: flex;
  align-items: center;
  gap: 15px;
}
.adv_search_wrap {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  /* width: 50%; */
}
.collapsible_title {
  /* width: 59%; */
  text-align: right;
  margin-right: 20px;
  margin-left: auto;
}
.advmodal_dropcontent {
  position: absolute;
  right: 0;
  background: #e2e2e2;
  z-index: 1;
  border-radius: 5px;
  padding: 15px 35px;
  margin-top: 10px;
  /* box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2); */
  border: 2px solid #ccc;
  width: 80%;
  padding-bottom: 25px;
}
.filter_container {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.adv_text {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.label_fname label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.label_fname {
  margin: 15px 0;
}
.input_fname {
  position: relative;
}
.input_fname span {
  position: absolute;
  top: 10px;
  right: 15px;
}
.mystyletext {
  padding-left: 20px;
}
.check_advance_labels {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

.nationality_wrap {
  position: relative;
  display: inline;
}
.input_nationality_wd {
  width: 250px;
  height: 40px;
  padding: 12px 20px 12px 12px;
  border-radius: 3px;
  border: solid 1px var(--primary_color);
  background-color: #e2e0e0;
  position: relative;
}
.pg_down_arrow i {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.inputsearch_result {
  width: 250px;
  height: 383px;
  padding: 16px 22px 20px 20px;
  margin-top: 0px;
  border: solid 1px #ccc;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.inputsearch_result input {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.advance_search_card_container {
  position: relative;
}
.right_advsearch {
  margin-left: 30px;
}
.advs_input {
  position: relative;
  margin-top: 20px;
}
.advs_input span {
  position: absolute;
  top: 10px;
  right: 15px;
}
.advance_search_card {
  position: absolute;
  background: #e2e2e2;
  border-radius: 5px;
  right: 0px;
  /* top:100px */
  top: 78px;
  z-index: 9;
  /* box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%); */
  border: 2px solid #ccc;
  padding: 26px;
  display: flex;
  flex-wrap: wrap;
  /* width: 900px; */
  z-index: 15;
}
.form_checkbox_adv {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.form_checkbox_adv > div {
  padding-right: 10px;
}
.input_container {
  display: inline-flex;
  gap: 10px;
  margin-bottom: 10px;
}
.dropdownfilter {
  position: relative;
}
.dropdownfilter {
  position: relative;
  width: 40%;
  margin-left: auto;
}
.dropmeallign {
  display: grid;
  margin-left: 20px;
  border: 1px solid #000000;
  padding: 10px;
  height: 170px;
  overflow-y: scroll;
  width: 258px;
  background: #fff;
}
.flight_cabin_class_ele {
  font-family: var(--font_family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

/* Traveller search design */

.gest_container {
  text-align: left;
}
.travel_gest p {
  font-size: 15px;
  margin-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-weight: 500;
}
.travlr_scroll {
  max-height: 140px;
  overflow: auto;
  padding-right: 5px;
}
.travlr_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.travlr_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}
.travlr_btn {
  cursor: pointer;
  color: #fff;
  padding: 5px 12px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary_color);
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.travlr_scroll::-webkit-scrollbar {
  width: 6px;
}

.travlr_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.travlr_scroll::-webkit-scrollbar-thumb {
  background: #666;
}

.travlr_scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary_color);
}

.travlr_scroll::-webkit-scrollbar-thumb:hover {
  background: var(--primary_color);
  opacity: 0.8;
}

.main_adv_multicity {
}

.advanc_addition_tabs {
  width: 100%;
  display: flex;
  gap: 3px;
  padding: 20px 15px;
}

.advance_search_card_container_multicity {
  width: max-content;
  position: absolute;
  right: 35px;
  top: 21px;
}

.adv_multicity_imp {
  width: 100%;
}

.advance_add {
  /* position: absolute;
  right: 35px; */
}
.collapsible_title_add {
  width: auto;
  text-align: right;
  margin-right: 20px;
  margin-left: auto;
  /* position: absolute;
  right: 210px; */
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

.adv_departure_time {
  width: 185px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  justify-content: space-evenly;
  height: 60px;
  border-radius: 3px;
  position: relative;
}

.time_icon {
  width: 20px;
  font-weight: 600;
  color: var(--secondary_color);
  fill: var(--primary_color);
}

.text_arrow_box {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text_arrow_box label {
  color: #545454;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}

.text_arrow_box span {
  color: #000;
  font-weight: 600;
}
.text_arrow_box svg {
  color: var(--secondary_color);
  width: 20px;
}
.text_box {
  display: flex;
  flex-direction: column;
}

.cabin_selector {
  width: 185px;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  padding: 0 5px;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}

.selector_icon {
  width: 25%;
  height: 100%;
  color: #016582;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector_text {
  cursor: pointer;
  width: 75%;
}

.secondary_text {
  text-transform: uppercase;
  color: #525252;
  font-size: 13px;
}

.primary_text {
  color: #000;
  font-weight: 600;
}

.drop_arrow {
  font-size: 30px;
  padding-top: 5px;
}

.departure_time_drop {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}
.departure_time_drop li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.departure_time_drop li {
  color: var(--primary_color);
}

.rbd_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}

.rbd_dropdown li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.rbd_input {
  padding: 5px 10px;
  width: 140px;
}

.rbd_input::placeholder {
  font-size: 14px;
}

.airline_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  width: 100%;
  box-shadow: 2px 2px 5px #858585bf;
}

.airline_dropdown li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.supplier_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}

.supplier_dropdown li {
  list-style: none;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.supplier_dropdown li:hover {
  background: var(--primary_color);
  color: #fff;
}

.drop_arrow svg {
  color: var(--secondary_color);
  width: 20px;
}

.departure_main_box {
  width: 175px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.departure_main_box .label_fname {
  color: grey;
  margin: 0;
}
.departure_main_box .label_fname label {
  color: grey;
  text-transform: uppercase;
  padding-left: 5px;
}

.multiSelectDepartureTimeDropdown #search_input::placeholder {
  display: none;
  /* color: red !important; */
}

.multiSelectContainer #search_input {
  height: 60px !important;
}

.imp_select_dep {
  /* background: lime; */
}

.imp_select_dep select{
  background: transparent;
  border: none;
  outline: none;
}

.imp_select_dep .multiSelectContainer .searchWrapper input.searchBox {
  /* background: red !important; */
}


.adv_tabs_split{
  width: max-content;
  display: flex;
  background: white;
  border-radius: 3px;
  height: 60px;
}

.adv_tabs_split input{
  /* border: none; */
}

.checkbox{
  border: 1px solid grey;
}

.icon_box_adv{
  width: 50px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_box_adv svg{
  width: 25px;
  height: 100%;
  fill: var(--primary_color);
}