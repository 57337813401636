.activity_search_wid_container{
    width: 100%;
    padding: 20px 25px 20px 31px;
    display: table;
    background: #e2e2e2;
    margin-bottom: 30px;
}
.activity_way_title{
    width: 100%;
    text-align: left;
}

.activity_way_title h2{
    font-family: var(--font_family);
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    text-align: left;
    color: var(--primary_color);
}
.activity_search_details_container{
    width: 100%;
    background: #e2e2e2;
    margin-top: 20px;
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column{
    float: left;
    margin-right: 3px;
    margin-bottom: 3px;
    position: relative;
    background: #ffff;
    border-radius: 3px;
    height: 67px;
    /* cursor: pointer; */

}
.activity_search_details_row .activity_search_column.activity_search_date{
    width: 28.1% !important;
}
.activity_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;   
}
.activity_search_wid_inputfield .activity_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}
.activity_search_details_main .activity_search_details_row .activity_search_name{
    width: 45%;
}
.activity_search_details_main .activity_search_details_row .activity_search_date{
    width: 42.1%;
}
.activity_search_details_main .activity_search_details_row .activity_search_accomodation{
    width: 22%;
}
.activity_search_widget_btn:hover, .activity_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}
.activity_search_details_row .activity_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 100%;
    height: 60px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
    text-transform: uppercase;
}
.activity_nw .activity_search_widget_btn{
    margin-top: 0;
    width: 200px;
    /* height: 3.3rem; */
    height: 4rem;
}
.activity_search_details_row .activity_search_class{
    /* margin-left: auto; */
    order: 2;
    margin-right: 3px;
    padding-left: 0;
    width: 22%;
}

.activity_search_accomodation .activity_cabin_class_cont{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.activity_search_column .activity_room_list{
    position: absolute;
    min-width: 265px;
    width: 100%;
    top: 60px;
    background-color: #ffffff;
    left: 0px;
    z-index: 99;
    box-sizing: border-box;
    max-height: 400px;
    box-shadow: 0px 0px 3px 0px #aaa;
    overflow: auto;
}

.activity_search_column .activity_room_list .activity_room_list_main{
    width: 360px;
    width: 100%;
    background-color: #ffffff;
    text-align: left;
    border-top: none;
    box-shadow: 0 0 18px 2px rgb(0 0 0 / 7%);
}

.activity_search_column .activity_room_list .activity_room_list_main .activity_room_detail_ele{
    text-align: left;
    padding: 10px 10px;
    width: 100%;
}

.activity_room_detail_ele .activity_room_title{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
    text-transform: uppercase;
}
.activity_room_head{    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.activity_room_remove{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    color: var(--font_color);
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
}
.activity_search_details_container .activity_search_details_row .activity_search_column .activity_search_wid_inputfield .activity_search_wid_inputfield_main .activity_search_error_message{
    position: relative;
    top: -10px;
    float: left;
    width: 104%;
}
.activity_search_details_container .activity_search_details_row .activity_search_column .activity_search_wid_inputfield .activity_search_wid_inputfield_main .activity_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.activity_accomodation_main >div{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.activity_accomodation_main >div img{
    position: relative;
    top: unset;
    left: unset;
}

.activity_accomodation_conf{
    display: flex;
    align-items: center;
}

.activity_accomodation_conf div{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    width: 50px;
    text-align: center;
}
.activity_accomodation_main span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
}

.activity_accomodation_main p{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    margin: 0px 0px;
    display: block;
}
.activity_room_detail_ele .activity_room_title span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
}

.activity_room_list_main .activity_room_detail_ele .activity_accomodation_main{
    display: block;
    margin-top: 0px;
    position: relative;
    width: 100%;
}

.activity_accomodation_conf .activity_accomodation_icon svg{
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: snow;
    border: 0;
    position: relative;
    top: unset;
    left: unset;
}

.age_list_ele{
    cursor: pointer;
    width: 100%;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    padding: 0 15px;
}

.disbale_traveller{
    pointer-events: none;
    cursor: default;
}

.disbale_traveller img{
    opacity: 0.5;
}

.done_button_main{
    width: 100%;
    text-align: left;
    padding-top: 25px;
    background: #fff;
}

.done_button_main .done_button{
    background-color: var(--buttons_color);
    border: none;
    color: #ffffff;
    padding: 11px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
}

.activity_search_details_row label{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-bottom: 5px;
    /* text-transform: uppercase; */
}

.activity_search_details_row >div{
    padding-left: 50px;
    padding-top: 3px;
}
.activity_nw{
    padding-left: 10px !important;
    padding-top: 0 !important;
}

.activity_search_details_row svg{
    position: absolute;
    left: 7px;
    top: 28px;
    width: 25px;
    height: 25px;
    color: var(--secondary_color);
}

.activity_search_details_row input,.activity_cabin_class_name
{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    border: 0 !important;
    padding: 0 10px 0 0;
    height: 30px;
    box-sizing: border-box;
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    margin-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    cursor: pointer;
}

.activity_search_details_row input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

.activity_autocomplete_value{
    position: absolute;
    width: 100%;
    max-height: 245px;
    z-index: 9;
    top: 65px;
    background-color: #fff;
    box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
}

    .activity_autocomplete_element{
            width: 100%;
            display: flex;
            padding: 5px 17px;  
            cursor: pointer;      
            align-items: center;
            min-height: 40px;
            position: relative;
    }

    .activity_autocomplete_element:hover,
    .activity_autocomplete_element:focus{
        background: #ccc;
    }


.activity_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.activity_autocomplete_tag{
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}


.activity_search_details_row ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .activity_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .activity_search_details_row ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }


  .activity_autocomplete_element img{
      position: relative;
      left: unset;
      top: unset;
      height: 23px;
      width: 23px;
      object-fit: scale-down;
      margin-right: 15px;
  }


.activity_add_rooms {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    display: flex;
    padding: 15px 10px;
    cursor: pointer;
}

.activity_add_rooms span{

    width: 23px;
    height: 23px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 23px;
    margin: -3px 10px 0 0;
    font-weight: normal;
}

.child_row_main label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 15px 0px 10px 0px;
    width: 100%;
    display: block;
}

.child_row_main input{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    width: 100%;
    height: 35px;
    padding-bottom: 5px;
    border-bottom: 1px#dcd8d8 solid !important;
    /* background: url(../image/down_arrow.jpg) center right no-repeat; */
    background-position: 97% 10px;
    cursor: pointer;
}

.child_row_main input::placeholder{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

/* ==================== Calendar ==================== */

.calendar_wid_container{
    width: 100%;
}
.calendar_wid_container .calendar_wid_main{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    margin-top: -3px;
}
.calendar_wid_container .calendar_container{
    position: absolute;
    width: 612px;
    margin-top: 0px;
    top: 60px;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--font_color);
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
    border: solid 1px #5d5b62;
    background-color: #ffffff;
    padding: 10px 8px;
}

.calendar_wid_container .calendar_container.left{
    left: 0 !important;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    /* text-transform: uppercase; */
    color: var(--font_color);
    padding-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    /* text-transform: uppercase; */
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cbe3f5;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.calendar_body{
    padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
    border-radius: 5px;
    color: #fff;
    background-color: var(--secondary_color);
}

.calendar_head_icon svg{
    position: relative !important;
    top: unset !important;
    right: unset !important;
}
.child_row_main{
    position: relative;
}
.child_age_select_arrow svg{
height: 15px;
width: 15px;
position: relative;
left: unset;
top: unset;
}

.child_row_main .child_age_select_arrow{
    position: absolute;
    right: 0;
    top: 43px;

}
.activity_search_wid_inputfield span img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
}
.activity_search_wid_inputfield span small {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--primary_color); 
    mask: url('/images/location-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/location-01.svg') no-repeat center / contain;

}
.activity_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column.activity_search_date span small{
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--primary_color); 
    mask: url('/images/calendar-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/calendar-01.svg') no-repeat center / contain;
}
.activity_autocomplete_element >span{
    width: 23px;
    height: 23px;
    background-color: var(--secondary_color); 
    mask: url('/images/location-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/location-01.svg') no-repeat center / contain;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 15px 0px 0px;
}
.calendar_wid_container img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
    /* color: var(--primary_color); */
}
.error{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
   /* margin-top: 15px; */
   margin-top: -3px;
}
.errordate{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
     /* margin-top: 10px; */
     margin-top: -4px;
}