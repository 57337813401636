/* .srpwidget_wrap {
    padding-bottom: 6px !important;
    padding-top: 2px !important;
} */
.srpwidget_wrap .flight_way_tab{
    height: 50px;
}


.flight_search_wid_container{
    width: 100%;
    /* padding: 30px 25px 50px 30px; */
    padding: 20px;
    display: table;
    background: #ffffff;
    margin-bottom: 30px;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.15);
    margin-top: 0px;
    position: relative;
}
.flight_way_tab{
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #dedede;
    padding-bottom: 0px;
    height: 80px;
}
.flight_way_btngrp{
    display: flex;
    flex-wrap: wrap;
    color: var(--primary_color);
    position: relative;
    /* max-width: 1200px;
    margin: auto; */
}
.flight_way_btngrp button svg{
    fill: var(--secondary_color);
}
.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message{
    position: relative;
    top: -6px;
    float: left;
    width: 100%;
    top: 10px;
    left: 20px;
}
.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.flight_search_error_message_pax {
    position: relative;
    top: 10px;
    left: 20px;
}

.flight_search_wid_container .flight_way_btngrp .active_way{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    background: #ebf2ff;
    border-radius: 15px;
    padding: 6px 15px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    margin-right: 20px;
    height: 30px;
}

.flight_search_wid_container .flight_way_btngrp .button_style{
    border-radius: 3px;
    padding: 5px 15px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-right: 20px;
    height: 30px;
}
.flight_search_wid_container .flight_way_btngrp svg {
    transform: rotate(180deg);
    margin-left: 5px;
    margin-top: 5px;
}



.flightsearchpage_widget .flight_way_btngrp .active_way{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    background: #ebf2ff;
    border-radius: 15px;
    padding: 6px 15px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary_color);
}
.flightsearchpage_widget .flight_way_tab{
    /* width: 1200px; */
    margin: auto;
    height: 45px;
}

.flightsearchpage_widget .flight_way_btngrp .button_style{
        border-radius: 3px;
    padding: 5px 15px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    display: flex;
    align-items: center;
}
.flightsearchpage_widget .flight_way_btngrp svg {
    /* transform: rotate(180deg); */
    margin-left: 5px;
    width: 13px !important;
}

.flight_way_btngrp .button_style:focus{
    color: var(--secondary_color);
}
.flight_way_btngrp .button_style:hover{
    color: var(--secondary_color);
}

.flight_search_details_container{
    width: 100%;
    /* width: 1200px;
    margin: auto; */
    margin-top: 0;
}

.flight_search_details_container .flight_search_details_main {
    position: relative;
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row{
    display: flex;
    position: relative;
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_search_column{
    float: left;
    margin-right: 15px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    border-bottom: 2px solid #dedede;
    padding-top: 10px;
}
.flight_search_column.flight_search_pax:last-child {
    border-bottom: 0 !important;
}
.advance_add{
    margin-left: auto;
}
.flight_search_column{
    float: left;
    margin-right: 15px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    border-bottom: 2px solid #dedede;
    padding-left: 0px;
    padding-top: 10px;
    /* width: 20%;
    display: flex; */
}
/* .flight_search_column.flight_search_depart_going_date .calendar_wid_container label{
    padding-left: 22px !important;
} */
.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_swap{
    position: absolute;
    left: 17%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    width: 30px;
    border-radius: 50%;
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_swap svg{
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
}
.flight_search_details_row .flight_search_depart{
    width: 22%;
}
/* .flight_search_depart::before{
    content: '';
    width: 20px;
    height: 10px;
    background: #fff;
    position: absolute;
    bottom: -15px;
    right: 50%;
    transform: translate(-50%, -50%);
    border-left: 50px solid white;
    border-right: 50px solid white;
    z-index: 999;
    border: 1px solid var(--secondary_color);
} */
.flight_search_details_row .flight_search_depart_date{
    width: 17%;
}
.flight_search_details_row .flight_search_depart_going_date{
    /* width: 17%; */
    width: 31%;
    cursor: pointer;
}
.flight_search_pax{
    height: 35px !important;
    display: flex !important;
    width: 100%;
}
/* .flight_search_pax .flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;   
} */
.flight_search_details_row .flight_search_pax{
    width: 100%;
    font-family: var(--font_family);
    padding-left: 30px;
    padding-top: 3px;
    padding-right: 15px;
}
.flight_search_details_row .flight_search_class{
    width: 20%;
    display: flex;
}

.flight_search_details_row .flight_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 140px;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}

.flight_search_details_row .flight_search_widget_btn:hover, .flight_search_details_row .flight_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}

.flight_search_details_container .flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
}

.flightsearchpage_widget_flex .flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    /* display: flex; */
    /* display: flex; */
    /* flex-wrap: wrap;    */
}
.flight_search_left_icon svg path {
    fill: var(--primary_color);
}
.flight_search_divpax{
    display: flex;
    width: 35%;
}
.flight_search_divpax small{
    padding: 0 5px;
}
.flight_search_divpax small svg{
    position: unset !important;
}
/* .flight_search_wid_inputfield .disable_pax_btn{
    opacity: 0.5;
} */
.flight_search_wid_inputfield > div svg{
    position: inherit !important;
    /* color: #fff; */
    /* background: #00a4f3; */
    margin-right: 0px !important;
    /* border-radius: 20px; */
    
}
.disable_pax_name svg{
    position: inherit !important;
}
/* .flight_search_wid_inputfield .add_pax{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: 0;
}

.flight_search_wid_inputfield .minus_pax{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: 0;
} */

.flight_search_wid_inputfield .disbale_traveller{
    opacity: 0.5;
}
   
.flight_search_wid_inputfield .flight_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
}   
    
.flight_search_details_main .flight_search_details_row:first-child label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;   
    text-transform: capitalize;
}
.flight_search_paxcount{
    display: flex;
    float: left;
    margin-right: 3px;
    position: relative;
    padding: 10px 10px 10px 0;
    width: 100%;
}
.flight_search_paxcount label{
    padding-left: 15px !important;
}
.flight_search_paxcount span{
    font-family: var(--font_family);
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 5px; 
}

.flight_search_details_main .flight_search_details_row:first-child>div{
    padding-left: 2px;
    padding-top: 8px;
    position: relative;
}
.flight_search_details_main .flight_search_details_row .flight_search_class svg{
    position: absolute;
    left: 0;
    top: 39px;
    width: 20px;
    height: 20px;
}
.flight_search_details_main .flight_search_details_row:first-child>div svg{
    /* position: absolute;
    left: 14px;
    top: 11px; */
    width: auto;
    height: 20px;
    width: 27px;
    object-fit: scale-down;
    /* margin-right: 5px; */
}
.flight_search_details_main .flight_search_details_row:first-child>div svg path{
    /* fill: #7f747c; */
}

.flights_search_main {
    position: initial !important;
    margin-top: 28px;
}
.flights_search_main svg {
    position: absolute !important;
    left: unset !important;
    right: 10px;
    width: 25px !important;
    height: 25px !important;
    top: 17px !important;
}
/* .flight_search_wid_inputfield svg{
    color: #fff;
    background: var(--secondary_color);
    border-radius: 20px;
} */
.Flt_tab_dsk{
    color: var(--font_color);
    position: absolute;
    top: -50px;
    left: 0;
    padding: 15px 40px;
    background: #fff;
    border-radius: 10px 10px 0 0;
    display: flex;
    gap: 3px;
}
.Flt_tab_dsk img{
    width: 20px;
    display: inline;
    margin-right: 5px;
}
.flight_search_details_main .flight_search_details_row:first-child>div input
{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    border: none;
    padding: 0 10px 0 0;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    /* background: transparent; */
}
.flightModify_wrap .flight_swapOnewayAdv{
    position: absolute;
    left: 17.5%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #def1fa;
    width: 45px;
    border-radius: 50px;
  }
  .flightModify_wrap .flight_swapOnewayAdv svg{
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }
   .flightModify_wrap .flight_swap_round{
    position: absolute;
    left: 13.2%;
    z-index: 6;
    top: 50px;
    width: 45px;
    cursor: pointer;
    background: #def1fa;
    border-radius: 50px;
}
 .flightModify_wrap .flight_swap_round svg{
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
}
.flightModify_wrap .flight_swapAdv{
    position: absolute;
    left: 10%;
    z-index: 6;
    top: 58px;
    width: 45px;
    cursor: pointer;
    background: #def1fa;
}
 .flightModify_wrap .flight_swapAdv svg{
    margin: auto;
    width: 33px;
    height: 33px;
}

.flight_search_details_main .flight_search_details_row:first-child>div input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

/* .flight_autocomplete_value{
    position: absolute;
    width: 100%;
    max-height: 50px;
    z-index: -2;
    top: 60px;
    background-color: rgb(175, 78, 78);
    box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
} */
.flights_city_main svg{
    left: 16px !important;
    width: 30px !important;
    height: 30px !important;
}
.flights_city_main svg:hover{
    fill: #ffffff;
}
.flight_autocomplete_element{
    width: auto;
    display: flex;
    padding: 5px 15px 5px 5px;  
    margin: 10px 15px;
    cursor: pointer;      
    align-items: center;
    min-height: 50px;
    gap: 10px;
    border-radius: 50px;
}

.flight_autocomplete_element:hover,
.flight_autocomplete_element:focus{
    background: var(--primary_color);
}
.flight_autocomplete_element:hover,
.flight_autocomplete_element:focus{
    background: var(--primary_color);
}

.flight_autocomplete_element:hover .flight_autocomplete_name,
    .flight_autocomplete_element:focus .flight_autocomplete_name{
       color:#fff;
    }
    .flight_autocomplete_element:hover .flight_autocomplete_name span,
    .flight_autocomplete_element:focus .flight_autocomplete_name span{
       color:#fff;
    }
    .flight_autocomplete_element:hover  path,
    .flight_autocomplete_element:focus  path {
       fill:#fff !important;
    }
    .flight_autocomplete_element  path,
    .flight_autocomplete_element  path {
       fill:#7F747C !important;
    }
    .flight_autocomplete_element:hover .flight_autocomplete_tag,
    .flight_autocomplete_element:focus .flight_autocomplete_tag {
       color:#fff !important;
    }
.flight_autocomplete_tag{
    padding: 3px 5px;
    /* background-color: #48a842; */
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.5; */
    letter-spacing: normal;
    text-align: center;
    color: var(--font_color);
    vertical-align: top;
    display: inline-block;
    /* border-radius: 7px; */
}

.flight_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.flight_autocomplete_name span{
    width: 100%;
    /* margin-top: 5px; */
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}

.search_widget_input_new{
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0;
    padding-left: 5px;
    font-weight: bold;
    font-size: 14px;
    color: var(--primary_color);
}
.search_widget_input_new::placeholder{
    color: var(--primary_color);
}
.search_widget_input_new:focus{
    box-shadow: none;
    border: none;
}
.flightmaintops:first-child .search_widget_input_new{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    border: none;
    padding: 0 10px 0 0;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    background: transparent;
}
.flight_search_left_icon{
    cursor: pointer;
    /* background-color: red; */
    float: left;
    position: absolute;
    left: 0;
    top: 28px;
}
.flight_search_details_container .flight_search_left_icon svg{
    display: block;
}
.flight_search_details_container .flight_search_left_icon svg path{
    fill: var(--primary_color) !important;
}
.flight_search_class svg path{
    fill: var(--primary_color) !important;
}
.flight_search_details_row ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  
  /* Track */
  .flight_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .flight_search_details_row ::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 10px;
  }
  .flight_search_pax span{
    margin-bottom: 10px;
    font-family: var(--font_family);
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0px;
    display: inline-block;
    color: var(--font_color);
  }

  
  .flight_search_pax label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0px;
    display: block;
    color: var(--font_color);
    margin-right: 3px;
  }

  .flight_search_pax .flight_search_wid_inputfield{
      display: flex;
      justify-content: space-between;
      /* margin-left: -15px; */
      margin-top: -4px;
      width: 35%;
      user-select: none;
  }

  .flight_search_pax .flight_search_wid_inputfield span{
      margin-bottom: 0;
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--font_color);
      padding: 4px 4px;
      position: relative;
      height: 30px;
      text-align: center;
      border: none;
      margin-top: -2px;
  }

  .flight_cabin_class_cont{
      /* display: flex;
      flex-direction: column; */
      font-family: var(--font_family);
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: var(--font_color);
      padding: 0 0 0px 0;
      cursor: pointer;
      /* width: 100%; */
      padding-left: 0px;
      padding-top: 0px;
  }

  .flight_trav_title{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    /* text-transform: capitalize; */
  }
  .flight_cabin_class_name{
    /* flex: 1 1; */
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    padding-top: 0px;
  }
  .flight_cabin_class_name.cabin_pd {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .flt_trav_label{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
  }
  .flight_cabin_class_cont img{
max-width: 25px;
margin: 0 17px 0 8px;
height: auto;
object-fit: contain;
  }
  .flight_cabin_class_list {
    position: absolute;
    width: 190px;
    border: 1px #e2e0e0 solid;
    top: 70px;
    left: 0px;
    border-top: none;
    background: #ffffff;
    z-index: 1;
}

.flight_cabin_class_ele {
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 10px 10px;
    margin: 0px 0px;
    display: block;
    padding: 10px 0px;
    padding-left: 0 !important;
    margin: 0px 10px;
    display: block;
    width: auto;
    border-bottom: 1px solid 
    #eaeaea;
}
.flight_cabin_class_ele:last-child{
    border: none;
}

.flight_cabin_class_ele:hover {
    /* background-color: var(--primary_color);
    color: #ffffff !important; */
    outline: none;
}

.flight_cabin_class_name{
    /* flex: 1 1; */
    /* margin-top: 13px; */
}
.flight_cabin_class_icon_img{
    height: 28px;
    width: 28px;
    color: var(--primary_color);
margin: 0 12px 0 8px;
}

.minus_pax, .add_pax{
    height: 23px;
    width: 23px;
    color: var(--secondary_color);
    cursor: pointer;
}

.flight_cabin_class_arrow svg{
    height: 15px;
    width: 15px;
    margin: 0 10px;
    color: var(--primary_color);
}


.flightautocomplete_main .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    /* padding: 0px 0px 10px 1px; */
    width: 100%;
    /* height: 30px; */
    background: none;
}

.flight_autocomplete{
    width: 15%;
}

.flightsearchpage_widget{
    padding: 20px 40px;
    /* margin: 0px 0px 10px 0px;     */
    /* background: #005a94; */
    background: #def1fa;
    /* border-radius: 15px; */
    margin-top: 0px;
}
.flightsearchpage_widget_flex{
    display: flex;
    position: relative;
    margin-top: 0px !important;
    position: relative;
    /* max-width: 1200px;
    margin: auto; */
}
.flightsearchpage_widget_flex label{
    font-family: var(--font_family);
    font-size: 14px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
}
.flightautocompletetraveller.passenger label{
    color: var(--primary_color);
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 90%;
    overflow: hidden;
}
.flightautocomplete{

}
.flightmaintops{
    display: flex;
    margin-top: 20px !important;
    position: relative;
    /* max-width: 1200px;
    margin: auto; */
    align-items: flex-end;
}
.flight_swap{
    position: absolute;
    left: 192px;
    z-index: 6;
    top: 47px;
    width: 30px;
    cursor: pointer;
    /* background: white; */
}
.flight_swap_round{
    position: absolute;
    left: 13%;
    z-index: 6;
    top: 50px;
    border-radius: 50px;
    /* width: 30px; */
    cursor: pointer;
    background: #fff;
    padding: 0 5px;
}
.flight_swap_round svg{
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
}
.flightModify_wrap .flight_swap{
    position: absolute;
    left: 15%;
    z-index: 6;
    top: 47px;
    width: 30px;
    cursor: pointer;
    background: #def1fa;
}
.flightModify_wrap .flight_swap svg{
    fill: var(--primary_color);
}
.flightautocomplete_main{
width: 19%;
margin-right: 20px;
/* background: #ffffff; */
/* border-radius: 15px; */
position: relative;
display: flex;
/* padding: 10px; */
border-bottom: 2px solid #b9d4e1;
    /* border-radius: 15px; */
    height: 60px;
    margin-left: 24px;
}
.flightautocomplete_main_new{
    width: 19%;
margin-right: 30px;
    position: relative;
display: flex;
/* padding: 10px; */
border-bottom: 2px solid #b9d4e1;
height: 60px;
}
.flightautocompletecalender{
    width: 19%;
    margin-right: 20px;
    display: block;
    /* background: #fffff; */
    /* border-radius: 15px; */
    /* padding: 0px 10px; */
    position: relative;
    border-bottom: 2px solid #b9d4e1;
    height: 60px;
}
.return_calendar input{
    /* color: var(--font_color) !important; */
    outline: none;
}
.flightautocompletetraveller{
    width: 100%;
    /* background: #ffffff; */
    padding: 6px 2px 6px 2px;
    /* border-radius: 15px 15px 0px 0px; */
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 2px solid #b9d4e1;
}
.flightautocompletetraveller label{
    margin: 0px;
    font-weight: bold;
    cursor: pointer;
}
.flightautocompletetraveller input{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding-left: 10px;
    width: 100%;
    height: 30px;
    background: none; 
    cursor: pointer;
}
.flightautocompletetraveller.passenger input{
    width: 10%;
    color: var(--primary_color);
    padding-right: 0;
}
.flightautocompletecalender input{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    /* padding: 0px 0px 10px 1px; */
    width: 100%;
    height: 30px;
    background: none; 
    cursor: pointer;
}
.dropPsg_icon{
    fill: var(--secondary_color);
    margin-left: auto;
}
.flightModify_wrap{
    max-width: 1270px;
    margin: auto;
    padding: 0 1rem;
}
.flightautocompletecalender .calendar_wid_main span{
    display: block;
    /* margin-left: 10px; */
    margin-top: 0px;
    cursor: pointer;
}

/* .flightautocompletecalender .calendar_wid_main{
    margin-top: 5px;
} */
.calendar_wid_main svg path{
    fill: var(--primary_color);
}
.flightautocompletetraveller span svg path{
    fill: var(--primary_color);
}
.flightautocomplemodifybutton button svg path {
    fill: #fff;
}
.flightsearchpage_widget .calendar_wid_container{
   position: relative;
    
}
.flight_autocomplete_value{
    position: absolute;
    width: 410px;
    max-height: 450px;
    z-index: 10;
    top: 65px;
    background-color: #fff;
    box-shadow: 0 19px 22.3px 6.7px hsla(0,0%,60%,.13);
    /* overflow: auto; */
    color: var(--primary_color);
    font-size: 12px;
    left: 0;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    /* overflow-y: scroll; */
}
.flight_autocomplete_value::-webkit-scrollbar{
    width: 5px;
    /* background: #a4a4a4; */
    border-radius: 20px;
}
.flight_autocomplete_value::-webkit-scrollbar-thumb{
    background: #cdccce;
    width: 5px;
    height: 50%;
    border-radius: 20px;
}

.flighttraveller_drop{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    background-color: #ffffff;
    width: 235px;
    z-index: 9;
    top: 47px;
    right: 0;
    border: 1px solid #e0e0e0;
    border-radius: 0 0 5px 5px;
}

.flighttraveller_drop_flex{
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    padding: 10px 10px 10px 10px;
    /* height: 50px; */
  
}
.flighttraveller_drop_adult{
    display: flex;
    flex-direction: column;
    width: 65%;
}
.flighttraveller_drop_paxconfig{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-end;
    /* border-bottom: 1px #dcd8d8 solid; */
}
.flighttraveller_drop_paxconfig .flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.flighttraveller_drop_economy{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 10px;
    margin-top: 30px;
    /* height: 50px; */
    position: relative;
}
.flighttraveller_drop .flighttraveller_drop_flex .flighttraveller_drop_adult label b{
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
}
.flighttraveller_drop .flighttraveller_drop_flex .flighttraveller_drop_adult label{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
}
.flighttraveller_drop_done{
    display: flex;
    align-items: center;
    justify-content: center;
}
.flighttraveller_drop_done button{
    width: 50% !important;
    /* min-height: 40px; */
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    background-color: var(--buttons_color);
    color: #fff;
    border: 1px solid var(--buttons_color);
    margin-top: 15px;
    cursor: pointer;
    height: auto !important;
    padding: 15px 0;
    margin-bottom: 15px;
    border-radius: 5px !important;
}
.flightautocomplemodify{
    width: 30%;
    margin-right: 10px;
    /* height: 68px; */
}
.flightautocomplemodifyn{
    width: 100%;
    margin-right: 10px;
    height: 65px;
    margin-top: 1px;
}
.flightautocomplemodify button{
    background-color: var(--buttons_color);
    border-radius: 10px;
    padding: 7px 30px;
    color: #fff;
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
}
.flightautocomplemodifybutton{
    width: 10%;
    margin-left: 5px;
}
.flightautocomplemodifybutton button{
    cursor: pointer;
    /* border-radius: 5px; */
    height: 45px;
    width: 100%;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    border-radius: 25px;
    text-align: center;
    background-color: var(--buttons_color);
    color: #fff;
    /* border: 1px solid var(--buttons_color); */
    /* border-radius: 0px 0px 15px 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 35px;
}
.flightsearchpage_widget_flex .flight_search_error_message {
    position: absolute;
    bottom: 0px;
    left: 48px;
    left: 0;
    bottom: -20px;
}

.flightsearchpage_widget_flex .flight_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    margin-top: 10px;
}
.calendar_wid_container .calendar_wid_main >span img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
    /* color: var(--primary_color); */
}
.flight_ptext p{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #383838; */
  color: var(--primary_color);
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
}
.flight_search_input{
    width: 100%;
}
.flight_cabin_class_arrow{
    position: relative;
}
.flight_cabin_class_arrow svg{
    position: absolute;
    left: 92px !important;
    top: -22px !important;
    width: 20px;
    height: 20px;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    color: var(--primary_color);
}
.flight_search_class_new{
    /* width: 19%; */
    padding-left: 0px !important;
    padding-top: 0px !important;
    /* position: absolute !important;
    bottom: -80px;
    right: 0px; */
}
.flight_search_class_new .flight_search_widget_btn{
    border-radius: 40px;
}
.flight_search_pax_select_main{
    display: block;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 9;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 0 0 5px 5px;
    right: 0%;
    width: 235px;
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16); */
}
.flight_cabin_class_arrow_pass{
    position: relative;
    width: 100%;
}
.flight_pax_title{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: var(--primary_color);
}
.flight_search_Pax_main .flight_search_pax label{
    padding-left: 0 !important;
}
.flight_search_Pax_main .cabin_class_list_them input{
    margin-left: 0;
    height: 20px;
    width: 20px !important;
}
.flight_cabin_bxd{
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}
.flight_cabin_bxd label{
    padding-left: 20px !important;
    padding-top: 0px;
    font-size: 14px;
}
.class_div{
    width: 100%;
}
.flight_cabin_class_arrow_pass svg{
    position: absolute;
    left: 25px!important;
    top: -5px!important;
    width: 20px;
    height: 20px;
    object-fit: scale-down;
    color: var(--primary_color);
}
.flight_search_leftpaxcount {
    width: 65%;
    display: flex;
}
.flight_search_pax_select_main .flight_search_pax{
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
    height: auto !important;
    padding-bottom: 15px;
}
.flight_search_pax_select_main .flight_search_pax:nth-child(4){
    border-bottom: transparent;
}
.flight_search_pax_newleft{
    width: 68%;
    display: grid;
}
.newcontainer{
    width: 100%;
    padding: 10px;
}


.pax_btn_thme{
    text-align: center;
}
.pax_btn_thme button{
    background-color: var(--buttons_color);
    border-radius: 10px;
    padding: 7px 30px;
    color: #fff;
}

.flight_cabin_class_list_them{
    margin-top: 10px;
    margin-bottom: 20px;
}

.pax_input{
    border: 1px solid #ddd!important;
    margin-bottom: 0;
    margin-left: 0;
    width: 20px !important;
    height: 20px !important;
    /* background: red; */
}
.flight_cabin_bxd .pax_input{
    width: 15px !important;
    height: 15px !important;
}
.cabin_class_sec {
    margin-top: 10px;
    display: inline-block;
}
.flighttraveller_drop_done{
    text-align: center;
}

.plane_img{
    background: none !important;
    color: var(--primary_color) !important;
    margin-left: 10px;
    fill: var(--primary_color);

}

.flight_autocomplete_value .arrow_wiget{
    position: absolute!important;
    top: -10px;
    left: 30%;
    background: none!important;
    width: 15px;
    height: 15px;
}

.flights_city_parent{
    height: 260px;
    overflow-y: scroll;
    border-radius: 10px;
}
.flight_autocomplete_value .flights_city_parent_unset p {
    font-size: 14px;
    color: var(--font_color);
    padding: 10px;
}
.flights_city_parent_unset {
    height: unset;
    border-radius: 10px;
}

.flights_city_parent::-webkit-scrollbar{
    width: 4px;
    background: transparent;
  }
  
  /* Track */
  .flights_city_parent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .flights_city_parent::-webkit-scrollbar-thumb {
    background: var(--primary_color);
    border-radius: 10px;
  }

.buttons_icon{
    padding: 3px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
}
.buttons_icon_disbale {
    opacity: 0.5;
  }

.flight_search_column .flight_search_Pax_main .arrow_pass{
    position: absolute !important; 
    top: -13px !important;
    left: 55% !important;
}

.pass_arrow_mod{
    position: absolute;
    width: 20px;
    height: 20px;
    top: -13px;
    left: 60%;
}


.flight_search_details_row_multicity{
    display: flex;
    justify-content: flex-end;
  }


  .flight_search_details_row_multicity .flight_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 200px;
    height: 60px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}


.flightsearchpage_widget_flex_multicity{
  display: flex;
  width: 50%;
  gap: 10px;
}

.flightautocomplete_main_multicity{
    width: 50%;
    /* margin-right: 35px; */
    display: flex;
    /* background-color: #fff; */
    /* border-bottom: 1px #dcd8d8 solid; */
    position: relative;
    /* padding: 10px; */
    border-bottom: 2px solid #b9d4e1;
    height: 65px;
    }
    .flightautocomplete_left{
    margin-right: 35px;
    }  

  .flightautocomplete_main_multicity .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    padding: 12px 5px 0 34px;
    width: 100%;
    /* height: 30px; */
    background: none;
    padding: 0;
    padding-left: 5px;
  }

  
  .flightautocompletecalender_multicity{
    width: 50%;
    margin-right: 10px;
    /* background-color: #fff; */
    border-bottom: 2px #b9d4e1 solid;
    display: flex;
    position: relative;
    height: 65px;
    }


.flightautocompletecalender_multicity input{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary_color);
  border: none;
  padding: 0px 0px 0px 1px;
  width: 100%;
  height: 30px;
  background: none; 
  cursor: pointer;
}

.flightautocompletecalender_multicity .calendar_wid_main span{
  display: block;
  margin-right: 10px;
  /* margin-top: -10px; */
  cursor: pointer;
}

.flightautocompletecalender_multicity .calendar_wid_main{
    margin-top: 2px;
    padding-top: 0;

}

.label_text label{
  color: #000;
  font-weight: 700;
}

.label_text span{
  padding: 0 !important;
  font-weight: 400 !important;
}


.flight_search_pax_multicity_modify{
  width: 23%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-left: 0 !important;
  border-bottom: 1px solid #dcd8d8;
  height: 100%;
}

.flightsearchpage_widget_multicity{
  padding: 0px 0px 10px 0px;
  margin: 0px 0px 10px 0px;
  display: flex;
  position: relative;
  /* width: 1200px;
  margin: auto; */
  margin-top: 20px;
}

.flight_search_pax_multicity_modify span{
  padding: 0 25px;
  font-weight: 700;
  stroke: .3px black;
}

.flight_search_pax_multicity_modify .minus_pax, .add_pax{
  color: grey !important;
}

.flight_search_class_modify_multicity{
  width: 25%;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcd8d8;
  position: relative;
}

.flightsearchpage_widget_flex_multicity_non_rep{
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
  height: 60px;
}

.flightsearchpage_widget_flex_multicity_non_rep .flightautocomplemodify{
  width: 30%;
}

.modifySearchWidget_divider{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flightsearchpage_widget_flex_multicity_modify{
  display: flex;
  width: 100%;
  gap: 10px;
  position: relative;
}

.remove_flight_button{
    /* position: absolute;
    right: -30px;
    top: 50%;
    transform: translate(-50%, -50%); */
    position: relative;
    right: 0;
    top: 0;
    cursor: pointer;
}
.flight_search_classMulticity{
    margin-top: 18px;
    width: 20%;
}
.flight_search_classMulticity svg{
    position: absolute;
    top: 39px;
}
.flight_search_classMulticity svg path{
    fill: var(--primary_color) !important;
}

.search_button_section{
    position: absolute !important;
    right: 0;
    /* height: 40px; */
    bottom: 31px;
    border: 1px solid var(--secondary_color);
    border: 0;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
}
.flight_swap_multicity{
    position: absolute;
    left: 27.5%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    width: 45px;
    border-radius: 50px;
}
.flight_swap_multicity svg{
    width: 35px !important;
    height: 35px !important;
    margin: auto;
}
.flight_swap_multicity svg path{
    fill: var(--primary_color) !important;
}

  
  .search_button_section {
      /* width: 40%; */
      float: right;
      margin-top: 100px;
    }
    .flightBtobflx{
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
    }
  
    .search_button_section button{
      color: var(--secondary_color);
      font-family: var(--font_family);
      font-weight: 600;
      font-size: 14px;
    }
    
    .flight_swap_modify{
        position: absolute;
        left: 102%;
        z-index: 2;
        top: 46px;
        cursor: pointer;
        color: var(--primary_color);
        /* background-color: #fff; */
        border-radius: 50%;
        border-color: var(--secondary_color);
    }
    .flight_swap_modify svg{
        fill: var(--primary_color);
    }
    .flightautocompletecalender_multicity .calendar_wid_container{
        /* padding: 10px; */
    }
    .flightsearchpage_widget_multicity .search_button_section{
        position: absolute !important;
        right: 0;
        /* height: 40px; */
        bottom: 0;
        /* border: 1px solid var(--secondary_color); */
        /* border-radius: 10px; */
        /* padding: 5px 10px; */
        cursor: pointer;
    }
    .flightsearchpage_widget_multicity .search_button_section button {
        color: var(--secondary_color);
        font-family: var(--font_family);
        font-weight: 500;
        font-size: 14px;
    }
    .flightsearchpage_widget_flex_multicity_modify .flightautocomplete_main_multicity .flight_search_wid_inputfield{
        /* display: flex; */
        align-items: center;
    }
    .flightsearchpage_widget_flex_multicity_modify .remove_flight_button svg{
        color: var(--primary_color);
    }
    .dropdown_arrowModify{
        position: absolute;
        right: -4.5%;
        top: 20px;
    }
    .dropdown_arrowModify svg{
        fill: #fff;
    }
    .dropArrow_mod{
        fill: var(--primary_color);
        margin-left: auto;
    }





/* ====================== Desktop cal Style ====================== */

.calendar_wid_container{
    width: 100%;
  
}

.calendar_wid_container .calendar_wid_main{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    padding-top: 5px;
   
}
.calendar_wid_container .calendar_container{
    position: absolute;
    width: 625px;
    margin-top: 0px;
    top: 75px;
    /* left: 50%; */
    transform: translate(-50%,0);
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--primary_color);
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/9%);
    border: 1px solid #e0e0e0;
    background-color: #fff;
    padding: 0px 0px;
}

.calendar_wid_container .calendar_container.left{
    left: 0 !important;
}

.calendar_wid_container .calendar_container.calendar_container_single{
    position: absolute;
    width: 320px;
    top: 65px;
    left: 50%;
    z-index: 10 !important;
    background-color: #fff;
}

.calendar_container .calendar_main{
    padding: 0px 0px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 2px;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 2px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 15px;
    width: 100%;
    /* border-bottom: 1px solid #ddd; */
    background: var(--primary_color);
    padding-top: 15px;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 10px;
    width: calc(97% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-decoration: none;
    border-radius: 5px;
    transition: color .3s,border .3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin: 3px 0px 3px 4px;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #dbdbdc; */
}


.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 13px;
    width: calc(100% / 7);
    
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}

.calendar_body{
    padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
    border-radius: 5px;
    color: #fff;
    background-color: var(--primary_color);
}
.calendar_head_icon svg{
    position: relative !important;
    /* top: unset !important;
    right: unset !important;
    color: var(--secondary_color) !important; */
    width: 30px;
    height: 30px;
    object-fit: scale-down;
    color: #fff;
    position: relative;
    top: 7px;
    left: -15px;
}

.button_style label {
    font-size: 14px;
    padding-left: 1.5em;
    color: var(--primary_color);
    font-weight: 600;
    position: relative;
    cursor: pointer;
    padding-top: 1px;
}

.active_way input, .button_style input {
    position: absolute;
    z-index: 5;
    opacity: 0;
    padding: 0 !important;
    cursor: pointer;
}
.active_way input[type="radio"] + label {
    font-weight: 600;
position: relative;
display: inline-block;
padding-left: 1.5em;
cursor: pointer;
-webkit-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.active_way input[type="radio"]:checked + label:before {
-webkit-transition: all .3s ease;
transition: all .3s ease;
box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em var(--secondary_color);
}

.active_way input[type="radio"] + label:before {
-webkit-transition: all .3s ease;
transition: all .3s ease;
box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}
.button_style input[type="radio"] + label:before,
 .button_style input[type="radio"] + label:after,
.active_way input[type="radio"] + label:before,
 .active_way input[type="radio"] + label:after {
content: '';
position: absolute;
top: 2px;
left: 0;
width: 15px;
height: 15px;
text-align: center;
color: white;
font-family: Times;
border-radius: 50%;
-webkit-transition: all .3s ease;
transition: all .3s ease;
border: 1px solid var(--secondary_color);
}
.button_style input[type="radio"] + label:after{
    border: 1px solid var(--font_color);
}
/* .flight_search_wid_container:before {
    content: "";
    display: block;
    position: absolute;
    top: -15px;
    left: 55px;
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 15px;
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -2px 2px 2px rgba(57,73,76,.2);
} */

.trav_pd0{
    padding-left: 0;
}
.flight_cabin_class_nameAdvance{
    flex: 1 1;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: var(--primary_color);
    padding-top: 10px;
    padding-left: 10px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 100px;
    min-width: 100px;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
.flight_travler{
    padding-left: 5px;
    padding-top: 0;
}
.flight_travIcon svg{}
.flight_travIconRound{
    position: absolute;
    top: 36px;
}
.flight_travIconRound svg{
    width: 20px;
    height: 20px;
}
.flight_travIconRnd {
    position: absolute;
      top: 37px;
  }
  .flight_travIconRnd svg{
    min-width: 22px;
    height: 100%;
  }
  .flight_travlerRnd{
    padding-top: 15px;
    padding-left: 25px;
  }
.flight_travIconMulti{
    position: absolute;
    top: 39px;
}
.flight_travIconMulti svg{
    /* width: 20px;
    height: 20px; */
    min-width: 22px;
    height: 100%;
    fill: var(--primary_color);
}
 .flight_travel_cont{
    padding-left: 0;
}

.flight_cabin_class_arrow4 svg{
    position: relative !important;
    right: 0;
    left: -4px !important;
    top: -13px !important;
    margin-left: auto;
}
.flight_cabin_class_arrow4 svg path, .flight_cabin_class_icon4 svg path{
    fill: var(--primary_color);
}
.flight_search_details_main .flight_search_details_row:first-child>div input::placeholder{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
}
.flightmaintops:first-child .search_widget_input_new::placeholder,  .flightautocomplete_main_multicity .search_widget_input_new::placeholder{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
}
.flight_search_depart_multicity, .flight_search_depart_going_date_multicity{
    width: 33%;
}

.flight_simple_wrap{
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 45px;
  
  }
  .flight_simpleBtn{
    background-color: var(--buttons_color);
      border: 1px solid var(--buttons_color);
      border-radius: 40px;
      width: 100%;
      padding: 8px 18px;
      font-family: var(--font_family);
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      /* margin-top: 3px; */
      margin-left: 0px;
      color: #ffffff;
      cursor: pointer;
      outline: 0;
  }

  .flight_swapOnewayAdv{
    position: absolute;
    left: 17.5%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    width: 45px;
    border-radius: 50px;
  }
  .flight_swapOnewayAdv svg{
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }

  .flight_swapAdv{
    position: absolute;
    left: 22%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    /* width: 45px; */
    padding: 0 5px;
    border-radius: 50px;
  }
  .flight_swapAdv svg{
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }
  .buttons_icon{
    background: transparent !important;
    border: 1px solid var(--secondary_color) !important;
  }
  .buttons_icon path{
    fill: var(--secondary_color) !important;
  }

  .wd_5{
    width: 5% !important;
  }
  .wd_10{
    width: 10% !important;
  }
  .wd_15{
    width: 15% !important;
  }
  .wd_20{
    width: 20% !important;
  }
  .wd_25{
    width: 25% !important;
  }
  .wd_30{
    width: 30% !important;
  }
.wd_35{
    width: 35% !important;
}
.wd_40{
    width: 40% !important;
}
.wd_100{
    width: 100% !important;
  }
  .flightmainbots{
    margin-top: 20px;
  }
  .flightfare{
    width: 18%;
    border-bottom: 2px solid #b9d4e1;
  }
  .flightfares{
    width: 100%;
    border-bottom: 2px solid #b9d4e1;
  }
  .flightmainfare{
    display: flex;
    gap: 5px;
    padding-top: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  .flightmainfare span{
    /* width: 16%; */
    padding: 0;
  }
  .flightmainfare span svg path{
    color: var(--primary_color);
  }
  .flightmainfare label{
    margin: 0px;
    width: 80%;
    font-size: 14px;
  font-weight: bold !important;
  color: var(--primary_color) !important;
  }
  .flightfare label{
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  }
  .flightfares label{
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  }
  .flightmaindrops{
    display: block;
  }
  .flightautocomplemodifymain{
    display: block;
    width: 20%;
  }
  .flightautocomplemodifysfare{
    margin-top: 20px;
  }

  .advance_search_card_container {
    position: relative;
    display: flex;
    align-items: center;
  }
  .traveller_button {
    width: 30px;
    height: 30px;
    padding: 5px;
    padding-top: 2px;
    line-height: 1;
    background-color: var(--primary_color);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50px;
  }
  .guesttext {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
    text-align: left;
    color: var(--primary_color);
    margin-left: 5px;
  }
  .calendar_container .calendar_main .calendar_head_icon{
    position: absolute;
    /* right: 0; */
    left: 10px;
    top: 0px;
    width: 100%;
  }
  .flight_search_details_main .flight_search_details_row:first-child>div .calendar_container .calendar_main .calendar_head_icon svg{
   width: 30px !important;
   height: 30px;
  }
  .calendar_container .calendar_main .calendar_head_icon svg path{
    fill: #fff !important;
  }

  .multicity_main{
    display: flex;
    justify-content: space-between;
    margin: 17px 0;
  }

  

.pointer{
    width: 20px !important;
    height: 20px;
}

.dropDownlistmain{
    background-color: #fff;
}
.maininput{
    border: none;
}
.subinput{
  margin: 0 11px 9px 10px;
  padding: 13px 57px 11px 12px;
  border: solid 1px #d6d6d6;
  background-color: #fff;
}








.dropDownlistmain {
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid #e0e0e0;
    margin-top: 4px;
    z-index: 9;
    width: 200px;
  }
  
  .dropDownlistmain ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .dropDownlistmain ul li {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  
  .dropDownlistmain ul li input[type="text"] {
    height: 37px;
    width: 100%;
    padding: 4px 15px;
    border: 1px solid 
    #d6d6d6;
    border-radius: 50px;
    background: #fff;
  }
  .dropDownlistmain ul li input[type="text"]::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--font_color);
    padding-left: 3px; 
  }
  
  .dropDownlistmain ul li input[type="checkbox"] {
    margin-right: 4px;
  }
  
  .dropDownlistmain ul li span {
    margin-right: 4px;
  }
  .dropdown_value_container{
    max-height: 280px;
    overflow: auto;
    position: relative;
    padding-top: 5px !important;
  }









  
  .adv_tabs_splitDepart .checkbox_label .bagCheckbox{
    position: relative;
    margin-left: 0 !important;
  }
  .checkbox_label{
    display: flex;
    cursor: pointer;
    align-items: flex-start;
    padding: 5px;
    justify-content: flex-end;
  }
  .checkbox_label .bagCheckbox input{
    cursor: pointer;
      height: 20px;
      opacity: 0;
      position: absolute;
      width: 20px;
      z-index: 2;
  }
  .checkbox_label .bagBox{
    align-items: center;
      background: #fff;
      border: 1px solid var(--secondary_color);
      border-radius: 2px;
      box-sizing: border-box;
      display: inline-block;
      display: flex;
      justify-content: center;
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: 0 !important;
  }
  
  .checkbox_label .bagCheck{
    border-color: #fff;
    -o-border-image: none;
    border-image: none;
    border-style: none solid solid none;
    border-width: medium 2px 2px medium;
    box-sizing: border-box;
    height: 11px;
    opacity: 0;
    position: relative;
    top: -2px;
    left: 0;
    transform: scale(1) rotate(45deg);
    transition-delay: .1s;
    width: 5px;
    z-index: 1;
    margin-left: 0;
  }
  .checkbox_label input[type=checkbox]:checked~.bagBox {
    background: var(--secondary_color);
    border: 0;
  }
  .checkbox_label .bagCheckbox input[type=checkbox]:checked~.bagBox .bagCheck {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
  .advTitle{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .38px;
    text-align: left;
    color: var(--primary_color);
    margin-left: 4px;
  }



.dropdown_value_container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.dropdown_value_container::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
.dropdown_value_container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.dropdown_value_container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.departure_main_box li{
  outline: none;
}
.classTitle {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .CabinLabel{
    padding-left: 10px;
  }
  
  .CabinLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .classTitle::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 15px;
    height: 15px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    transition: all .3s ease;
    border: 1px solid var(--secondary_color);
  }
  
  .CabinLabel input:checked ~ .classTitle::before {
    border-color: var(--secondary_color); 
  }
  
  .CabinLabel input:checked ~ .classTitle::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 1px;
    background-color: var(--secondary_color);
    width: 13px;
    height: 13px;
    text-align: center;
    color: white;
    border-radius: 50%;
    border: 3px solid #fff;
  }
  
  /* .classTitle:hover::before {
    border-color: #555;
  } */
  
  .CabinLabel input:disabled ~ .classTitle::before {
    background-color: #ccc;
  }

.flighttraveller_drop_economy .flight_cabin_bxd input{
   color: unset !important; 
}
.active_way input[type=radio]+label {
    font-weight: 600;
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.active_way input[type=radio]:checked+label:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em #fff,inset 0 0 0 1em var(--secondary_color);
}
.active_way input[type=radio]+label:after, .active_way input[type=radio]+label:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 15px;
    height: 15px;
    text-align: center;
    color: #fff;
    font-family: Times;
    border-radius: 50%;
    transition: all .3s ease;
    border: 1px solid var(--secondary_color);
}
.autocomplet_wrap{
    padding: 0;
    padding-top: 10px;
    display: flex;
    align-items: center;
}
.autocomplet_wrap .flight_search_left_icon{
    position: relative;
    top: auto;
    left: auto;
}
.autocomplet_wrap .flight_search_left_icon svg{
    position: relative;
    background: none;
}
.flt_pasngrFlx{
    /* padding-top: 11px; */
    padding-left: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.pasngrFlx .flight_cabin_class_name{
    display: inline-block;
    margin-right: 2px;
}

.pd_tp{
    padding-top: 12px;
}
.cabin_pd{
    padding-left: 22px;
    padding-top: 12px;
}
.multicity_fare_container{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.dropdown_Flx{
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
  .dropdown_Flx svg{
    height: auto !important;
    width: 18px;
  }
  .pax_main svg{
    border-radius: 20px;
  }

.flight_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.error_srp{
    margin-top: 15px;
}
.error_srp span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}

.dropDownlistmain ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .dropDownlistmain ul li {
    display: flex;
    align-items: center;
    padding: 0px;
    cursor: pointer;
    margin-bottom: 1px;
  }
  
  .dropDownlistmain ul li input[type="text"] {
    height: 37px;
    width: 100% !important;
    padding: 4px 15px !important;
    border: 1px solid 
    #d6d6d6 !important;
    border-radius: 50px !important;
    background: #fff !important;
  }
  .dropDownlistmain ul li input[type="text"]::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--font_color);
    padding-left: 3px; 
  }
  
  .dropDownlistmain ul li input[type="checkbox"] {
    margin-right: 4px;
  }

  .fltg_icon svg{
    outline: 0;
  }
  .flightBtobflx>div>div>div>div:nth-child(1){
    margin-top: 0px;
  }

  .adv_tabs_split{
    display: flex;
    position: relative;
  }
  .label_fare label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0;
    text-transform: capitalize;
  }
  .icon_box_advInput{
    display: flex;
    align-items: center;
    padding-top: 3px;
  }
  .fare_main_box{
    margin-right: 15px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    border-bottom: 2px solid #dedede;
    padding-top: 10px;
  }
  .icon_box_advFare{
    align-self: flex-start;
    margin-top: 2px;
  }
  .mt_25{
    margin-top: 25px;
  }
  .icon_box_advFare svg {
    width: auto !important;
    height: 20px;
}

.icon_box_advFare svg path {
    fill: var(--primary_color);
}
.flight_Adv_wrapMU{
    text-align: right;
    margin-left: auto;
    display: flex;
    align-items: center;
}
.flight_AdvBtnMU{
    background-color: var(--buttons_color);
      border: 1px solid var(--buttons_color);
      border-radius: 40px;
      /* width: 100%; */
      padding: 8px 18px;
      font-family: var(--font_family);
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      /* margin-top: 3px; */
      margin-left: 0px;
      color: #ffffff;
      cursor: pointer;
      outline: 0;
  }
  .calendar_wid_main input
{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: var(--primary_color);
    border: none;
    padding: 0 10px 0 0;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    background: transparent;
}
.calendar_wid_main input:focus{
    border: none;
    box-shadow: none;
}
.mt_15{
    margin-top: 15px;
}


/* new advanced search css */
.advance_container{
    padding: 0;
    /* margin-top: 20px; */
    margin-bottom: 5px;
    display: flex;
    gap: 10px;
    width: 80%;
}
.advance_container .adv_class{

}
.advance_container .departure_main_box {
    margin-right: 15px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    border-bottom: 2px solid #dedede;
    padding-top: 10px;
}
.advance_container .icon_Rbd_advInputnw{
    display: flex;
    gap: 5px;
    padding-top: 5px;
}
.advance_container .icon_Rbd_advInputnw .icon_box_adv svg{
    width: auto;
    height: 20px;
    object-fit: scale-down;
    margin-top: 1px;
}
.advance_container .icon_Rbd_advInputnw .icon_box_adv svg path{
    fill: var(--primary_color);
}
.advance_container .icon_Rbd_advInputnw div span{
    padding-left: 0;
}
.dropdown_Flx input {
    caret-color: transparent;
}
.advance_container .adv_tabs_split input {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
    border: none;
    width: 100%;
    padding: 0;
    background: transparent;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    /* display: table-column-group; */
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
.advance_container .adv_tabs_split input::placeholder{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
}
.advance_container .adv_tabs_split input:focus{
   border: none;
   box-shadow: none;
}
.advance_container .cabin_cont_flx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.advance_container .cabin_flx{
    display: flex;
    align-items: center;
}
.advance_container .flight_cabin_arrowMn{
    padding: 0;
}
.advance_container .flight_cabin_class_nameAdvance{
    padding-top: 0;
}
.wd_33{
    width: 33% !important;
}
.dropdown_container{
    width: 100%;
}
.label_nameAdv{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;
    text-transform: capitalize;
}
.srpwidget{
    background: #f4fcfc;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #def1fa;
    margin-top: 50px;
    }
    .srpwidget_wrap{
        width: 1270px !important;
    margin: 0 auto;
    padding-top: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
    /* height: 300px; */
    max-height: 900px;
    padding-bottom: 2px;
    }

    .trave_flx{
        display: flex;
        align-items: center;
        padding-top: 5px;
    }
    .advMN_flx{
        display: flex;
        padding-top: 3px;
    }
    .icon_box_adv{
        margin-top: 2px;
    }
    .icon_box_adv svg {
        width: auto !important;
        height: 20px;
    }
    .icon_box_adv svg path{
      fill: var(--primary_color);
    }
    .advTrav_flx{
        display: flex;
        padding-top: 5px;
    }
    .trav_mainCont{
        position: relative;
    }
    .flight_travIcon{
        margin-top: 0px;
    }
    .flight_travIcon svg {
        width: auto !important;
        height: 20px;
    }
    .flight_travIcon svg path{
      fill: var(--primary_color);
    }
    .pointer {
        width: 20px !important;
        height: 20px;
    }
    .adv_tabs_splitDepart .checkbox_label {
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        align-items: flex-start;
    }
    .adv_tabs_splitDepart .checkbox_label input[type="checkbox"]:checked ~ .bagBox {
        background: var(--secondary_color);
        border: 0;
    }
    .adv_tabs_splitDepart .advTitle {
        font-family: var(--font_family);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.38px;
        text-align: center;
        color: var(--primary_color);
    }
    .adv_tabs_splitDepart .advTitle {
        position: relative;
        padding-left: 25px !important;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .adv_tabs_splitDepart .checkbox_label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .adv_tabs_splitDepart .advTitle::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 15px;
        height: 15px;
        background-color: #fff;
        border: 2px solid #7f747c;
        border-radius: 50%;
    }
    .adv_tabs_splitDepart .checkbox_label input:checked ~ .advTitle::before {
        border-color: var(--secondary_color);
    }
    .adv_tabs_splitDepart .checkbox_label input:checked ~ .advTitle::after {
        content: "";
        position: absolute;
        top: 3px;
        left: 1px;
        background-color: var(--secondary_color);
        border-radius: 50%;
        width: 7px;
        height: 7px;
        text-align: center;
        color: #fff;
        font-family: Times;
        border-radius: 50%;
        margin: 3px;
    }
    .adv_tabs_splitDepart .advTitle:hover::before {
        border-color: #555;
    }
    .adv_tabs_splitDepart .checkbox_label input:disabled ~ .advTitle::before {
        background-color: #ccc;
    }
 .srpwidget_nrml .flight_oneway {
        position: absolute;
        left: 22%;
        z-index: 1;
        top: 50px;
        cursor: pointer;
        color: var(--primary_color);
        background-color: transparent;
        padding: 0 5px;
        border-radius: 50px;
    }
    .srpwidget_nrml .flight_roundTrip {
        position: absolute;
        left: 22%;
        z-index: 1;
        top: 50px;
        cursor: pointer;
        color: var(--primary_color);
        background-color: transparent;
        padding: 0 5px;
        border-radius: 50px;
    }
    .srpwidget_nrml .flight_onewayAdvance,
    .srpwidget_Advance .flight_onewayAdvance{
        left: 27%;
    }
    .srpwidget_nrml .flight_multicity,
    .srpwidget_Advance .flight_multicity {
        position: absolute;
        left: 30%;
        z-index: 1;
        top: 50px;
        cursor: pointer;
        color: var(--primary_color);
        background-color: transparent;
        padding: 0 5px;
        border-radius: 50px;
    }

    .flight_way_btngrp .button_style{
        border-radius: 3px;
        padding: 5px 15px;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        transition: .3s;
        font-family: var(--font_family);
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: .4px;
        text-align: center;
        display: flex;
        align-items: center;
    }
    
    .flight_way_btngrp .active_way {
        font-family: var(--font_family);
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: .4px;
        text-align: center;
        color: var(--secondary_color);
        background: #ebf2ff;
        border-radius: 15px;
        padding: 6px 15px;
        transition: .3s;
        display: flex;
        align-items: center;
        border: 1px solid var(--secondary_color)
    }
    .dropdown_container{
        width: 100%;
      }

      .rbderr{
        font-size : 11px;
        color: #e8664e;
        margin-top: 12px;
        display: block;
      }
/* new advanced search css */
.advance_flx{
    display: flex;
    /* align-items: center; */
    align-items: flex-start;
}
.flight_cabin_class_cont label{
    line-height: 1.5;
}
.pd_10{
    padding-top: 10px;
}

.srpwidget_nrml{
   outline: 0;
}
.srpwidget_nrml .flight_oneway,
.srpwidget_nrml .flight_roundTrip,
.srpwidget_nrml .flight_multicity{
    background-color: white;
    margin-top: 8px;
}
.srpwidget_Advance .flight_oneway,
.srpwidget_Advance .flight_roundTrip,
.srpwidget_Advance .flight_multicity
{
    /* background-color: #f4fcfc; */
    background-color: #def1fa;
    margin-top: 8px;
}
.remove_flight_buttonMulti, .remove_flight_buttonMultiAdv{
    align-items: center;
}
.remove_flight_buttonMulti svg, .remove_flight_buttonMultiAdv svg{
    width: 30px !important;
    height: 30px !important;
}

/* .checkbox_label_flexy{
    float: right;
} */

.flight_guest_chkbox{
    position: absolute;
    right: 0px;
    top: 0px;
}
.flight_guest_chkbox span{
    margin-right: 10px;
}
.flight_guest_chkbox input{
    margin-right: 10px;
}
.trip-type-container {
    width: 100%;
    display: flex;
}
.trip_type_container{
    width: 100%;
    margin-bottom: 8px;
}
.trip_type_container span {
    margin-right: 30px;
    color: var(--font_color);
}

.trip_type_container span input{
    margin-right: 12px;
}
.trip_type_heading{
    color: #000!important;
}

.hotelTrip input{
    margin-right: 10px;
}
.hotelTrip label {
    padding-top: 2px;
}
.roundText{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.45px;
    text-align: left;
    color: var(--primary_color);
    white-space: nowrap;
    padding-left: 10px;
    cursor: pointer;
}
.hide{
    display: none;
}
.flight_newoneway_swap{
left: 31% !important;    
}

.flight_colleage_error_message {
    font-size: 11px;
    color: #e8664e;
    margin-top: 0px;
    text-align: right;
    position: absolute;
    right: 20px;
    margin-top: -19px;
}
.flexalign{
    display: flex;
    align-items: center;
}
.dropdown_value_container li:last-child .checkbox_label {
    margin-bottom: 5px;
}


@media screen and (min-width: 320px) and (max-width: 768px){
    .flight_search_error_message_pax {
        position: relative;
        top: 0px;
        left: 0px;
    }
    .dropdown_value_container {
        padding-top: 15px !important;
    }
    .dropdown_value_container li:last-child .checkbox_label  {
        margin-bottom: 5px;
    }
    .dropdown_above {
        position: absolute;
        top: auto;
        bottom: 100%;
    }
  div.multicity_main{
    display: block;
    margin-top: 0;
  }
  div.popular_destination1 {
    display: block;
    margin-left: 5px;
  }
  .trip_type_container{
    margin-top: 05px;
}

    .tab_submenu_content_srp{
        position: sticky;
        top: 0px;
        z-index: 9;
    }
    .flight_colleage_error_message {
        font-size: 11px;
        color: #e8664e;
        text-align: left;
        position: absolute;
        margin-top: -6px;
    }
    .trip_type_container span {
        margin-right: 5px;
        max-width: 123px;
        display: inline-block;
    }
    .trip_type_container span input{
        margin-right: 3px;
    }
    
    .flight_way_btngrp .button_style{
        border-radius: 3px;
        padding: 5px 15px;
    }
    .trip_type_container span {
        margin-right: 5px;
    }

    .traveller_button
    {
        padding: 0px;
        width: 30px;
    }
    .mob_flight_search_wid_container{
        width: 100%;
        padding-bottom: 10px;
    }
    .mob_flight_search_wid_container .mob_flight_search_wid_main{
        padding: 0;
        background: #e7e9eb;
        /* height: 100vh; */
        padding-bottom: 10%;
    }
    .mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible{
        display: block;
        box-shadow: 4px 4px 10px #e6e6e6;
    }
    .mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header{
        display: flex;
        height: 75px;
        background: #fff;
        align-items: center;
        padding: 0px 15px;
        /* margin-top: 25px; */
    }
    .mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image{
        display: inline-block;
    }
    .mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image svg{
        width: 20px;
        cursor: pointer;
        height: 20px;
        margin-right: 10px;
        color: var(--primary_color);
    }
    .mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .search_filter_header{
        font-family: var(--font_family);
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.75px;
        text-align: left;
        color: var(--primary_color);
    }
    .flight_mobile_widget{
        background: white;
        width: 95%;
        margin: auto;
        margin-top: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding-top: 10px;
        border-radius: 15px;
        padding-bottom: 5px;
    }
    .mob_flight_way_tab {
        width: -webkit-fill-available;
        text-align: center;
        background: #fff;
        margin: 15px 10px;
        display: flex;
        height: 40px;
    }
    .mob_flight_way_tab .mob_flight_way_btngrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .mob_flight_way_btngrp .active_way {
        font-family: var(--font_family);
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        font-weight: 600;
        letter-spacing: 0.4px;
        text-align: center;
        transition: 0.3s;
        width: 100%;
        height: 40px;
        font-weight: 700;
        color: var(--secondary_color);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background: #ecf2ff;
    }
    .mob_flight_way_btngrp .button_style {
        background: 0 0;
        border: none;
        outline: 0;
        cursor: pointer;
        transition: 0.3s;
        font-family: var(--font_family);
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: var(--font_color);
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
    .button_style input {
        border: 1px solid var(--secondary_color);
    }
    .mob_flight_way_btngrp .button_style:focus {
        color: var(--primary_color);
    }
    .mob_flight_way_btngrp .button_style:hover {
        color: var(--primary_color);
    }
    .mob_flight_way_btngrp label {
        white-space: nowrap;
        font-size: 12px;
    }
    .class_div {
        width: 100%;
    }
    .active_way input {
        position: absolute;
        z-index: 5;
        opacity: 0;
        padding: 0 !important;
    }
    .active_way input[type="radio"] + label {
        font-weight: 600;
        position: relative;
        display: inline-block;
        padding-left: 1.5em;
        cursor: pointer;
    }
    .active_way input[type="radio"]:checked + label:before {
        box-shadow: inset 0 0 0 0.2em #fff, inset 0 0 0 1em var(--secondary_color);
    }
    .active_way input[type="radio"] + label:before {
        box-shadow: inset 0 0 0 0.2em #fff, inset 0 0 0 1em #fff;
    }
    .active_way input[type="radio"] + label:after,
    .active_way input[type="radio"] + label:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        text-align: center;
        color: #fff;
        font-family: Times;
        border-radius: 50%;
        border: 1px solid var(--secondary_color);
    }
    .active_way input[type="radio"] + label {
        font-weight: 600;
        position: relative;
        display: inline-block;
        padding-left: 1.5em;
        cursor: pointer;
    }
    .active_way input[type="radio"]:checked + label:before {
        box-shadow: inset 0 0 0 0.2em #fff, inset 0 0 0 1em var(--secondary_color);
    }
    .active_way input[type="radio"] + label:after,
    .active_way input[type="radio"] + label:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        text-align: center;
        color: #fff;
        font-family: Times;
        border-radius: 50%;
        border: 1px solid var(--secondary_color);
    }
    .button_style input[type="radio"] + label:before,
 .button_style input[type="radio"] + label:after,
.active_way input[type="radio"] + label:before,
 .active_way input[type="radio"] + label:after {
    top: 4px;
 }
 .flight_way_tab .button_style input[type="radio"] + label:before,
 .flight_way_tab .button_style input[type="radio"] + label:after,
.flight_way_tab .active_way input[type="radio"] + label:before,
 .flight_way_tab .active_way input[type="radio"] + label:after {
    top: 0px;
 }
 .cabin_class_sec {
    margin-top: 2px;
 }
 .button_style input[type="radio"] + label:after {
    border: 1px solid var(--secondary_color);
}
.button_style label {
    color: var(--font_color);
}
.flight_search_details_container{
    width: 100%;
}
.mob_flight_search_details_main {
    padding: 10px;
}
.advance_flx {
    flex-direction: column;
    align-items: flex-start;
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row{
    flex-direction: column;
    width: 100%;

}
.advance_container{
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
}
.adv_tabs_split,
.advn_box{
    width: 100% !important;
}
.advance_container .departure_main_box{
    margin-right: 0;
    height: auto;
    border-bottom: 2px solid transparent;
    padding-top: 0;
}
.adv_tabs_split,
.advn_box{
    margin-bottom: 30px;
    position: relative;
    background: #ffff;
    border-radius: 10px;
    height: 67px;
    border: 1px solid #d4d4d4;
    padding: 3px 10px;
}
.travMn_flx{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
}
.mob_flight_search_details_main .mob_flight_search_details_row .mob_flight_search_column{
    margin-bottom: 30px;
    position: relative;
    background: #ffff;
    border-radius: 10px;
    height: 67px;
    border: 1px solid #d4d4d4;
    padding: 3px 10px;
}
.mob_flight_search_column,
.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_search_column{
    width: 100% !important;
    margin-bottom: 30px;
    position: relative;
    background: #fff;
    border-radius: 10px;
    height: 67px !important;
    border: 1px solid #d4d4d4;
    padding: 3px 10px !important;
}
.advance_container .icon_Rbd_advInputnw{
    align-items: flex-start;
}
.srpwidget_nrml .flight_oneway,
.srpwidget_nrml .flight_roundTrip,
.srpwidget_nrml .flight_multicity
{
    left: inherit !important;
    right: 0;
    top: 57px;

}

.flight_cabin_class_cont{
    width: 100% !important;
    margin-bottom: 30px;
    position: relative;
    background: #fff;
    border-radius: 10px;
    height: 67px;
    border: 1px solid #d4d4d4;
    padding: 3px 10px;
    margin-right: 0;
}
.flight_search_error_message span {
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 20px;
    display: block;
}
.advance_container .icon_Rbd_advInputnw div span{
    justify-content: space-between;
}
.advance_container .icon_Rbd_advInputnw div span input{
    outline: none;
    border: none;
    width: 100%;
    height: 24px;
    padding-left: 0;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: var(--primary_color)!important;
}
.advance_container .icon_Rbd_advInputnw div span input:focus{
    outline: none;
    box-shadow: none;
}
.checkbox_label {
    display: flex;
    cursor: pointer;
    align-items: flex-start;
    padding: 0 5px;
    justify-content: flex-end;
    margin: 25px 0;
    margin-top: 0;
}
.checkbox_label .bagCheck{
    top: -1px;
    left: 0px;
}

.advn_box label {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;
    text-transform: capitalize;
}
.inner_passng, .mob_flight_search_column p {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: var(--primary_color);
}
.inner_rbdIcon svg path {
    fill: var(--primary_color);
}
.inner_passng, .mob_flight_search_column p {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: var(--primary_color);
}
.flight_autocomplete_value{
    width: 100%;
    z-index: 7;
}
.flighttraveller_drop {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    z-index: 9;
    top: 65px;
    right: 0;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    padding: 10px;
}

.flighttraveller_drop_flex {
    display: flex;
    padding: 10px 0;
    margin-bottom: 3px;
    border-bottom: 1px solid #dedede;
}
.flighttraveller_drop .flighttraveller_drop_flex .flighttraveller_drop_adult label{
    font-family: var(--font_color);
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--primary_color);
    margin: 0;
}
.flighttraveller_drop_adult span {
    font-size: 11px;
    text-transform: uppercase;
}
.flighttraveller_drop .flight_search_wid_inputfield {
    width: 50%;
    margin-left: 0!important;
    display: flex;
    align-items: center;
    gap: 10px;
}
.flighttraveller_drop .flighttraveller_drop_flex:last-child{
    border-bottom: 0;
}
.search_button_section{
    position: relative !important;
    margin: 0;
    top: 0;
    bottom: 0;
    float: none;
    padding: 0 10px;
    margin-bottom: 5px;
}
.flight_AdvBtnMU{
    width: 100%;
    height: 55px;
    font-size: 18px;
}
.calendar_wid_main input{
    color: var(--primary_color) !important;
}

.calendar_wid_container_popup{
    z-index: 100;
    position: fixed;
    min-width: 333px;
    min-height: 100%;
    background: white;
    /* overflow: auto; */
    left: 0;
    top: 0;
    right: 0;
    /* bottom: 0; */
}
.calendar_wid_container_popup > img{
    height: 20px !important;
    width: 20px !important;
    z-index: 99;
    top: 10px !important;
    position: absolute !important;
    right: 12px !important;
    left: initial !important;
}
.calendar_wid_container_popup .calendar_head_container{
    width: 100%;
    top: 0;
    /* position: sticky; */
    left: 0px;
    z-index: 2;
    background-color: rgb(255, 255, 255);
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main{
    width: 100%;
    display: flex;
color: var(--font_color);
    padding: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_right,
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_left{
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
}
.calendar_head_main span{
    display: block;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main h5 {
    border-bottom: 2px dashed #ccc;
    display: inline-block;
    font-weight: bold;
}
.calendar_wid_container .calendar_main_body_popup{
    overflow: auto;
    height: 98vh;
    padding:  20px 30px 70px;
    width: 100%;
    display: block;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    text-transform: uppercase;
    color: var(--font_color);
    width: 100%;
    background-color: transparent;
    padding: 0;
    background: var(--primary_color);
    height: 45px;
}
.calendar_body{
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px dashed #ccc;
    margin-bottom: 0;
    border: 0;
}
.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date
/* ,.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span */
{
    background: url(/images/selected_to.svg)no-repeat;
    background: var(--secondary_color);
    height: 30px;
    width: calc(100% / 7);
    background-size: contain;
    background-position: 55%;
}
.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date span{
    font-weight: bold;
    color: #fff;
}
.srpwidget_wrap{
    width: 100% !important;
    padding: 0;
}
 .mobile_visible{
    display: block;
    box-shadow: 4px 4px 10px #e6e6e6;
}
 .mobile_visible .mobo_tab_header{
    display: flex;
    height: 75px;
    background: #fff;
    align-items: center;
    padding: 0px 15px;
    /* margin-top: 25px; */
}
 .mobile_visible .arrow_image{
    display: inline-block;
}
.mobile_visible .arrow_image svg{
    width: 25px;
    cursor: pointer;
    height: 25px;
    margin-right: 10px;
    color: var(--primary_color);
}
 .mobile_visible .search_filter_header{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: var(--primary_color);
}
.srpwidget {
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #e7e9eb;
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: auto;
}
.srpwidget .flight_way_tab{
    border-bottom: 0;
    height: auto;
}
.srpwidget .flight_way_btngrp .button_style{
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 5px;
}
.srpwidget .flight_way_btngrp .button_style label,
.srpwidget .flight_way_btngrp .active_way label{
    font-family: var(--font_family);
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .4px;
    text-align: left;
    transition: .3s;
    width: 100%;
    font-weight: 700;
}
.srpwidget .flight_way_btngrp .active_way{
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    gap: 5px;
    background: #ecf2ff;
    padding: 6px 6px;
    width: 110px;
}
.srpwidget .flight_mobile_widget{
    padding: 10px;
    
}
.srpwidget .flightBtobflx {
    display: block;
    justify-content: flex-end;
    margin-left: 0;
    padding-top: 15px;
    width: 100%;
}
.addguestbtn-width{
    width: 100%;
}


.srpwidget .srpwidget_Advance .flight_oneway,
.srpwidget .srpwidget_Advance .flight_roundTrip,
.srpwidget .srpwidget_Advance .flight_multicity
{
    background-color: #fff;
    margin-top: 8px;
    left: inherit;
    right: 0;
    top: 57px;
}
.remove_flight_buttonMulti, .remove_flight_buttonMultiAdv {
    padding-top: 0 !important;
    margin-bottom: 20px;
}
.flight_search_pax_select_main{
    width: 100%;
    border-radius: 10px;
}

/* Ritesh-cssshome */
/* .flight_search_column{    
border: none;
margin: 0px;
height: unset;
padding: 0px;
} */
.flight_cabin_class_list{
    position: absolute;
    width: 100%;
    border: 1px #e2e0e0 solid;
    top: 63px;
}
.dropDownlistmain{
    width: 100%;
    padding: 10px;
    z-index: 7;
}
.dropDownlistmain .adv-type-here{
    margin-bottom: 10px;
}

.flight_search_column .flight_cabin_class_cont{
    border: none;
    height: unset;
    margin-bottom: 0;
  
}
.dropDownlistmain .checkbox_label .bagCheck {
    top: -1px;
    left: 7px;
}


.adv_tabs_split .flight_search_column{
    height: unset;
    margin: 0px;
    padding: 0px;
    border: none;
}
.Offlinewidget{
    padding: 0;
}



.Offlinewidget .flight_search_wid_container {
    padding: 0;
}

.Offlinewidget .flight_mobile_widget {
    padding: 10px;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.Offlinewidget button.button_style label {
    font-size: 12px;
}
.Offlinewidget button.button_style{
        margin-right: 0 !important;
}
.Offlinewidget .flight_search_wid_container .flight_way_btngrp .active_way {
    font-size: 12px;
    margin-right: 0;
}
.Offlinewidget .roundText{
    padding-left: 0;
}
.calendar_head .calendar_head_center_side h3 {
    padding-top: 14px;
    padding-left: 7px;
    text-align: center;
    color: #fff;
}

.advance_container .departure_main_box,
.adv_tabs_split .flight_search_column,
.flight_search_column .flight_cabin_class_cont,
.advance_container .departure_main_box,
.advance_container .departure_main_box
{
    position: static;
}


}


.enquiy_container{
    width: 100%;
    text-align: center;
    padding: 27px 0;
}
.send_success{
    text-align: center;
    margin-bottom: 35px;

}
.send_success img{
    width: auto;
    height: 51px;
    margin: 0 auto;
    margin-bottom: 18px;
}
.send_lbl{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--primary_color);
}

.quote_lblTitle{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--font_color);
}

.quote_success{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--primary_color);
}
.enquire_wrap{
    margin-bottom: 23px;
}

.ok_button{
    padding: 0 28px;
    height: 37px;
    border-radius: 30px;
    background-color: var(--buttons_color);
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
}

.btn_container{
    margin-top: 28px;
}
.popular_destination1{
    display: flex;
    margin-top: 0px;
    align-items: center;
}
.populardest_title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.45px;
    text-align: left;
    color: var(--primary_color);
    margin-right: 20px;
    margin-right: 20px;
  }
  
  .popular_destination {
    background-color: #ececec;
    padding: 9px;
    border-radius: 0 0 8px 8px;
    border-top: none;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .popular_destiSRP{
    margin-top: 15px;
    background: transparent;
  }
  
  .destination_container {
    display: flex;
    gap: 20px;
  }
  
  .destination_active .destination_name {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  
  .destination_item .property_count {
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: var(--font_color);
  }
  
  .destination_active .property_count {
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: #fff;
  }
  
  .destination_item {
    border: 1px solid #000;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    position: relative;
  }
  .fare_tooltip{
    border-radius: 6px;
    padding: 10px 15px;
    position: absolute;
    z-index: 9;
    top: 130%;
    left: 50%;
    border: 1px solid #d8d8d8;
    line-height: 18px;
    font-size: 12px;
    width: 285px;
    transform: translateX(-50%);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    color: #000;
    font-weight: 500;
    background: #fff;
  }
  .fare_tooltip:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 18px);
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #d8d8d8;
}
.fare_tooltip:after {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 17px);
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
}
  
  .destination_active {
    background-color: var(--primary_color);
    border: 1px solid var(--primary_color);
    border-radius: 10px;
   padding: 10px;
   position: relative;
   cursor: pointer;
  }
  .specialfare{
    border-radius: 11.9px;
    background-color: #ffe3d3;
    padding: 5px 10px;
    height: 25px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: #f58648;
  }
  .specialfare_disable{
    pointer-events: none;
    opacity: 0.7;
  }
  .no_result_found {
    padding: 5px 10px;
  }