.flight_search_wid_container{
    width: 100%;
    padding: 20px 25px 20px 31px;
    display: table;
    background: #e2e2e2;
    margin-bottom: 30px;
    position: relative;
}
.flight_way_tab{
    width: 100%;
    text-align: left;
}
.flight_way_btngrp{
    display: flex;
    flex-wrap: wrap;
    color: var(--primary_color);
    padding-bottom: 20px;
}
.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message{
    position: relative;
    top: -11px;
    float: left;
    width: 104%;
}
.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.flight_way_btngrp .active_way{
    border: 2px solid;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    border-radius: 3px;
    padding: 5px 15px;
    transition: 0.3s;
}

.flight_way_btngrp .button_style{
    border-radius: 3px;
    padding: 5px 15px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--font_color);
}

.flight_way_btngrp .button_style:focus{
    color: var(--secondary_color);
}
.flight_way_btngrp .button_style:hover{
    color: var(--secondary_color);
}

.flight_search_details_container{
    width: 100%;
    background: #e2e2e2;
    /* padding-top: 15px; */
}

.flight_search_details_container .flight_search_details_main {
    position: relative;
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row{
    /* display: flex;
    flex-wrap: wrap; */
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_search_column{
    float: left;
    margin-right: 3px;
    margin-bottom: 3px;
    position: relative;
    background: #ffff;
    border-radius: 3px;
    height: 60px;
    padding-left: 45px;
}

.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_swap{
    position: absolute;
    left: 25.5%;
    /* z-index: 99; */
    z-index: 9;
    top: 19px;
    width: 30px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    border-radius: 50%;
}
.flight_search_details_row .flight_search_depart{
    width: 27.1%;
}

.flight_search_details_row .flight_search_depart_date{
    width: 22%;
}
.flight_search_details_row .flight_search_depart_going_date{
    width: 22%;
    cursor: pointer;
}

.flight_search_details_row .flight_search_pax{
    width: 18%;
    min-width: 208px;
    font-family: var(--font_family);
    padding-left: 30px;
    padding-top: 3px;
    padding-right: 15px;
}
.flight_search_details_row .flight_search_class{
    width: 22%;
    display: flex;
}

.flight_search_details_row .flight_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 100%;
    height: 60px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}

.flight_search_details_row .flight_search_widget_btn:hover, .flight_search_details_row .flight_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}

.flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;   
}

.flight_search_wid_inputfield .disable_pax_btn{
    opacity: 0.5;
}

/* .flight_search_wid_inputfield .add_pax{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: 0;
}

.flight_search_wid_inputfield .minus_pax{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: 0;
} */

.flight_search_wid_inputfield .disbale_traveller{
    opacity: 0.5;
}
   
.flight_search_wid_inputfield .flight_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
}    
    
.flight_search_details_main .flight_search_details_row:first-child label{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    /* padding-bottom: 5px; */
   
}

.flight_search_details_main .flight_search_details_row:first-child>div{
    /* padding-left: 50px;
    padding-top: 3px; */
    position: relative;
}
.flight_search_details_main .flight_search_details_row:first-child>div svg{
    position: absolute;
    left: 4px;
    top: 17px;
    width: 28px;
    height: 28px;
    object-fit: scale-down;
    color: var(--primary_color);
}
.flight_search_details_main .flight_search_details_row:first-child>div input
{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    border: none;
    padding: 0 10px 0 0;
    height: 30px;
    box-sizing: border-box;
    width: 100%;
    box-shadow: none;
    cursor: pointer;
}

.flight_search_details_main .flight_search_details_row:first-child>div input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

/* .flight_autocomplete_value{
    position: absolute;
    width: 100%;
    max-height: 50px;
    z-index: -2;
    top: 60px;
    background-color: rgb(175, 78, 78);
    box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
} */

.flight_autocomplete_element{
    width: 100%;
    display: flex;
    padding: 5px 17px;  
    cursor: pointer;      
    align-items: center;
    min-height: 70px;
}

.flight_autocomplete_element:hover,
.flight_autocomplete_element:focus{
    background: #ccc;
}

.flight_autocomplete_tag{
    padding: 1px 5px;
    background-color: #48a842;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    vertical-align: top;
    display: inline-block;
    border-radius: 7px;
}

.flight_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.flight_autocomplete_name span{
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}

.search_widget_input_new{
    cursor: pointer;
}
.flight_search_left_icon{
    cursor: pointer;
}

.flight_search_details_row ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .flight_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .flight_search_details_row ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
  .flight_search_pax span{
    margin-bottom: 10px;
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    display: inline-block;
    color: var(--font_color);
  }

  
  .flight_search_pax label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    display: inline-block;
    color: var(--font_color);
    margin-right: 3px;
  }

  .flight_search_pax .flight_search_wid_inputfield{
      display: flex;
      justify-content: space-between;
      margin-left: -15px;
      margin-top: -4px;
      user-select: none;
  }

  .flight_search_pax .flight_search_wid_inputfield span{
      margin-bottom: 0;
      font-family: var(--font_family);
      font-size: 19px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--font_color);
      padding: 4px 4px;
      position: relative;
      height: 30px;
      text-align: center;
      background-color: #ffffff;
      border: none;
      margin-top: -2px;
  }

  .flight_cabin_class_cont{
      display: flex;
      align-items: center;
      font-family: var(--font_family);
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: var(--font_color);
      padding: 0 0 0px 0;
      cursor: pointer;
      width: 100%;
  }
  .flight_cabin_class_cont img{
max-width: 25px;
margin: 0 17px 0 8px;
height: auto;
object-fit: contain;
  }
  .flight_cabin_class_list {
    position: absolute;
    width: 170px;
    border: 1px #e2e0e0 solid;
    top: 100%;
    left: 20px;
    border-top: none;
    background: #ffffff;
    z-index: 1;
}

.flight_cabin_class_ele {
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 10px 10px;
    margin: 0px 0px;
}

.flight_cabin_class_ele:hover {
    background-color: var(--primary_color);
    color: #ffffff;
}

.flight_cabin_class_name{
    /* flex: 1 1; */
    padding-top: 20px;
    font-weight: 700;
}
.flight_cabin_class_icon_img{
    height: 28px;
    width: 28px;
    color: var(--primary_color);
    margin: 0 12px 0 8px;
}

.minus_pax, .add_pax{
    height: 23px;
    width: 23px;
    color: var(--primary_color);
    cursor: pointer;
}

.flight_cabin_class_arrow svg{
    height: 15px;
    width: 15px;
    margin: 0 10px;
    color: var(--primary_color);
}


/* ====================== Desktop Style ====================== */

.calendar_wid_container{
    width: 100%;
    
}
.calendar_wid_container .calendar_wid_main{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    margin-top: -1px;
}
.calendar_wid_container .calendar_container{
    position: absolute;
    width: 612px;
    margin-top: 0px;
    top: 60px;
    left: 0%;
    transform: translate(-50%,0);
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--font_color);
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
    border: solid 1px #5d5b62;
    background-color: #ffffff;
    padding: 10px 8px;
}

.calendar_wid_container .calendar_container.left{
    left: 0 !important;
}

.calendar_wid_container .calendar_container.calendar_container_single{
    position: absolute;
    width: 320px;
    top: 65px;
    left: 50%;
    z-index: 10 !important;
    background-color: #fff;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    text-transform: uppercase;
    color: var(--font_color);
    padding-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 10px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.calendar_body{
    padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
    border-radius: 5px;
    color: #fff;
    background-color: var(--secondary_color);
}
.calendar_head_icon svg{
    position: relative !important;
    top: unset !important;
    right: unset !important;
    color: var(--secondary_color) !important;
}

.flightautocomplete_main .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding: 0px 0px 10px 1px;
    width: 100%;
    height: 30px;
    background: none;
}

.flight_autocomplete{
    width: 15%;
}

.flightsearchpage_widget{
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
}
.flightsearchpage_widget_flex{
    display: flex;
}
.flightsearchpage_widget_flex label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
}
.flightautocomplete{

}
.flight_swap{
    position: absolute;
    right: -14px;
    z-index: 1;
    top: 28px;
    width: 30px;
    cursor: pointer;
}
.flightautocomplete_main{
width: 15%;
margin-right: 35px;
display: flex;
border-bottom: 1px #dcd8d8 solid;
position: relative;
}
.flightautocompletecalender{
    width: 15%;
    margin-right: 35px;
    border-bottom: 1px #dcd8d8 solid;
    display: flex;
}
.flightautocompletetraveller{
    width: 15%;
    margin-right: 35px;
    border-bottom: 1px #dcd8d8 solid;
}
.flightautocompletetraveller input{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding: 0px 0px 10px 1px;
    width: 100%;
    height: 30px;
    background: none; 
    margin-top: 8px;
}
.flightautocompletecalender input{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding: 0px 0px 10px 1px;
    width: 100%;
    height: 30px;
    background: none; 
    cursor: pointer;
}

.flightautocompletecalender .calendar_wid_main span{
    display: block;
    margin-right: 10px;
    margin-top: -10px;
    cursor: pointer;
}

.flightautocompletecalender .calendar_wid_main{
    margin-top: 8px;
}
.flightsearchpage_widget .calendar_wid_container{
   position: relative;
    
}
.flight_autocomplete_value{
    position: absolute;
    width: 300px;
    max-height: 245px;
    z-index: 3;
    top: 60px;
    background-color: #fff;
    box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
    border: 1px #dcd8d8 solid;
}
.flighttraveller_drop{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    width: 240px;
    z-index: 9;
 
}
.flighttraveller_drop_flex{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 0px 10px;
    height: 50px;
  
}
.flighttraveller_drop_adult{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.flighttraveller_drop_paxconfig{
    display: flex;
    flex-direction: column;
    width: 50%;
    border-bottom: 1px #dcd8d8 solid;
}
.flighttraveller_drop_paxconfig .flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.flighttraveller_drop_economy{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 10px;
    height: 50px;
    position: relative;
}
.flighttraveller_drop .flighttraveller_drop_flex .flighttraveller_drop_adult label b{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
}
.flighttraveller_drop .flighttraveller_drop_flex .flighttraveller_drop_adult label{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
}
.flighttraveller_drop_done{

}
.flighttraveller_drop_done button{
    width: 100%;
    min-height: 40px;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    background-color: var(--buttons_color);
    color: #fff;
    border: 1px solid var(--buttons_color);
    margin-top: 15px;
    cursor: pointer;
}
.flightautocomplemodify{
    width: 10%;
}
.flightautocomplemodify button{
    cursor: pointer;
    border-radius: 5px;
    height: 45px;
    width: 100%;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    margin-top: 7px;
    background-color: var(--buttons_color);
    color: #fff;
    border: 1px solid var(--buttons_color);
}

.flightsearchpage_widget_flex .flight_search_error_message {
    position: absolute;
    bottom: -18px;
    width: 125%;
}

.flightsearchpage_widget_flex .flight_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    margin-top: 10px;
}
.calendar_wid_container .calendar_wid_main >span img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
    /* color: var(--primary_color); */
}



/* MULTICITY */

.container_widget {
    width: 100%;
    margin: auto;
    background: #e2e2e2;
    /* padding: 20px 0px; */
    position: relative;
  }
  
  .flight_widget_togglers {
    padding-top: 10px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }
  
  .toggler_btns {
    width: 40%;
    display: flex;
    gap: 40px;
    align-items: center;
  }
  
  .btn_toggler {
    font-size: 19px;
    background: none;
    border: none;
    padding: 5px 15px;
    border-radius: 3px;
    cursor: pointer;
    color: #016582;
  }
  .btn_toggler:hover {
    border: 2px solid #016582;
  }
  .btn_toggler.active {
    border: 2px solid #016582;
    font-weight: 600;
  }
  
  .advance_filters {
    width: 40%;
    font-family: Roboto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 50px;
  }
  .advance_search {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .advance_search .active {
    display: block;
  }
  
  .add_btn_adv {
    background: #016582;
    padding: 5px 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 2px;
    font-weight: 100;
    transition: 0.5s;
    display: none;
  }
  
  .minus_btn_adv {
    background: #016582;
    padding: 5px 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 2px;
    font-weight: 600;
    transition: 0.5s;
    display: none;
  }
  
  .advance_filter_text {
    color: #525252;
  }
  
  .add_btn_adv:hover {
    opacity: 0.8;
  }
  
  .count_btn_num {
    background: #016582;
    padding: 5px 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 2px;
    font-weight: 400;
    transition: 0.5s;
  }
  
  .first_row{
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 5px;
  }
  
  .selectors_boxes {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* row-gap: 10px; */
    column-gap: 5px;
    padding-top: 10px;
  }
  
  .departure_selector {
    width: 17%;
    height: 60px;
    background: white;
  }
  
  .selector_icon {
    width: 25%;
    height: 100%;
    color: #016582;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .departure_selector {
    display: flex;
    align-items: center;
  }
  
  .selector_text {
    cursor: pointer;
    width: 75%;
  }
  
  .secondary_text {
    text-transform: uppercase;
    color: #525252;
    font-size: 13px;
    font-family: Roboto;
  }
  
  .primary_text {
    font-family: Roboto;
    padding: 5px 0px;
  }
  
  .departure_dropdown {
    background: #ffffff;
    width: 17.3%;
    height: 320px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow-y: scroll;
    z-index: 1000;
  
  
  
    display: none;
  }
  
  .going_to_dropdown{
      left: 17.5%;
  }
  
  .departure_dropdown::-webkit-scrollbar {
    width: 5px;
  }
  
  .departure_dropdown::-webkit-scrollbar-thumb {
    background: #808080a2;
    width: 5px;
  }
  
  .departure_dropdown::-webkit-scrollbar-track {
    background: #80808050;
  }
  
  .dropdown_option {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: 0.3s;
    font-family: Roboto;
  }
  
  .dropdown_option:hover {
    background: #80808050;
  }
  
  .city_text {
    font-size: 17px;
    font-weight: 600;
    font-family: Roboto;
    padding-bottom: 5px;
  }
  
  .airport_name {
    font-size: 14px;
    color: #525252;
    padding: 15px 0;
  }
  
  .option_text {
    width: 80%;
  }
  
  .option_code {
    background: #48a842;
    border-radius: 5px;
    color: white;
    font-size: 13px;
    padding: 2px;
    font-weight: 500;
    font-family: Roboto;
  }
  
  .airport_code {
    padding: 3px 5px;
  }
  
  .going-to_selector {
    width: 16.6%;
  }
  
  
  .city_input {
    border: none;
    outline: none;
    padding: 7px 0px;
    width: 100%;
    background: transparent;
    cursor: pointer;
  }
  
  .city_input::placeholder {
    font-size: 16px;
    color: black;
    font-family: Roboto;
    padding-bottom: 5px;
  }
  
  .departure_date_selector {
    width: 15%;
    background: white;
    display: flex;
    align-items: center;
    height: 60px;
  }
  
  .date_input {
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: 600;
    color: black;
    font-family: Roboto;
    padding: 2px 0px;
    width: 100%;
    background: transparent;
  }
  
  .adult_counter_selector {
    background: white;
    width: 12%;
    height: 60px;
  }
  
  .adult_counter_selector .selector_text {
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-family: Roboto;
    padding-top: 10px;
  }
  
  .adult_selector_text {
    color: #525252;
  }
  
  .adult_selector_text span {
    font-weight: 500;
    color: black;
  }
  
  .counter_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    margin: auto;
  }
  
  .counter_minus,
  .counter_plus {
    color: #525252;
    font-size: 24px;
    font-weight: 100;
    opacity: 0.5;
  }
  
  .counter_num {
    font-size: 20px;
    font-weight: 600;
    font-family: Roboto;
    display: flex;
    align-items: center;
  }
  
  .child_counter_selector {
    background: white;
    width: 13%;
    height: 60px;
  }
  
  .child_counter_selector .selector_text {
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-family: Roboto;
    padding-top: 10px;
  }
  
  .infant_counter_selector {
    background: white;
    width: 13%;
    height: 60px;
  }
  
  .infant_counter_selector .selector_text {
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-family: Roboto;
    padding-top: 10px;
  }
  
  .class_wrapper {
    width: 10%;
  }
  
  .class_selector {
    background: white;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .class_selector .selector_icon {
    font-size: 21px;
    height: max-content;
    padding-top: 5px;
  }
  
  .class_selector .selector_text {
    width: max-content;
    font-weight: 500;
    font-size: 13px;
    font-family: Roboto;
  }
  .drop_arrow {
    font-size: 30px;
    padding-top: 5px;
  }
  
  .class_dropdown {
    position: absolute;
    background: white;
    top: 50%;
    width: max-content;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 10;
    display: none;
  }
  
  .class_option {
    list-style: none;
    padding: 10px 7px;
    font-size: 13px;
    transition: 0.4s;
    cursor: pointer;
    font-family: Roboto;
  }
  
  .class_option:hover {
    background: #e2e2e2;
  }
  
  .next_city {
    position: absolute;
    width: 17.3%;
    top: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
  
    display: none;
  }
  
  .next_city .going_to_dropdown{
      left: 17.5% !important;
  }
  
  .search_button_section {
    /* width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Roboto;
    gap: 30px;
    margin-top: -55px;
    position: relative; */
    width: 40%;
    float: right;
    margin-top: 20px;
  }


  .search_button_section button{
    color: var(--primary_color);
    font-family: var(--font_family);
    font-weight: 500;
    font-size: 14px;
  }
  
  .more_flights {
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #016582;
    font-weight: 500;
  }
  
  .btn_search_flight {
    width: 250px;
    height: 40px;
    border-radius: 5px;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500;
    outline: none;
    border: none;
    background: #016582;
    font-family: Roboto;
    cursor: pointer;
    transition: .5s;
  }
  
  .btn_search_flight:hover{
      opacity: .8;
  }
  
  .cabin_selector{
      width: 14%;
      height: 60px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 10px;
      padding: 0 5px;
      cursor: pointer;
  }
  
  .departure_wrapper {
    width: 17%;
  }
  
  .departure_wrapper .departure_selector {
    width: 100%;
  }
  
  .advance_disabled{
    display: none;
  }
  
  
  
  .sec_row{
    /* background: red; */
    width: 100%;
    gap: 5px;
    display: flex;
  }
  
  .flight_search_depart_multicity{
    width: 32%;
  }

  .flight_search_depart_going_date_multicity{
    width: 34%;
  }


  .flight_search_depart_going_date_multicity#myTextField{
    font-size: 18px !important;
  }

  .flight_search_details_main .flight_search_details_row:first-child>div input{
    font-size: 18px;
  }

  .flight_swap_multicity{
    position: absolute;
    left: 28.5%;
    z-index: 9;
    bottom: 5px;
    width: 30px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    border-radius: 50%;
  }

  .flight_search_pax_multicity{
    width: 23%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding-left: 0 !important;
  }

  .label_text{
    display: flex;
    text-align: center;
  }
  
  .label_text span{
    font-size: 12px;
  }

  .flight_search_class_multicity{
    width: 28%;
  }

  .flight_search_details_row_additional{
    display: flex;
    width: 100%;
    margin-top: 20px;
  }

  .flight_search_depart_multicity_additional{
    float: left;
    margin-right: 3px;
    margin-bottom: 10px;
    position: relative;
    background: #fff;
    border-radius: 3px;
    height: 60px;
    padding-left: 50px;
    width: 15%;
  }

  .flight_search_depart_multicity_additional label{
    font-size: 12px;
    font-weight: 600;
    padding-top: 5px;
  }

  .flight_search_depart_multicity_additional input{
    width: 100%;
    padding: 5px 0 !important;
    border: none;
  }

  .flight_search_depart_going_date_multicity_additional{
    width: 15%;
    height: 60px;
    background: #fff;
  }

  .flight_search_depart_going_date_multicity_additional .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding: 0px 0px 10px 1px;
    width: 100%;
    height: 30px;
    background: none;
}

  .flight_search_depart_multicity_additional svg{
  position: absolute;
  left: 7px;
  top: 18px;
  color: var(--primary_color);
  }

  .flight_search_depart_going_date_multicity_additional label{
    padding-left: 40px;
    font-size: 12px;
    font-weight: 600;
  }
  
  .flight_search_depart_going_date_multicity_additional input{
    width: 100%;
    border: none;
    padding: 5px 0 !important;
    font-weight: 600;
    font-size: 18px;
    margin-left: 10px;
  }

   #increase{
    position: absolute;
    left: 0;
    top: 3px;
  }

  .flight_cabin_class_arrow_additional{
    position: absolute;
      top: 0;
      right: 40px;
  }

  .flight_cabin_class_arrow_additional svg{
    width: 18px;
    height: 18px;
  }


  .additional_row{
    width: 100%;
  }

  .additional_row .flight_search_details_container{
    margin-top: 5px;
    padding: 10px 0;
  }


  .flight_search_pax_multicity .flight_search_wid_inputfield{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .flight_search_pax_multicity .flight_search_wid_inputfield svg{
    position: initial !important;
  }

  .flight_search_class_multicity .flight_cabin_class_name .flight_cabin_class_arrow_additional svg{
    width: 18px !important;
    height: 18px !important;
  }

  .repeat_comp{
    width: 100%;
  }

  .non_repeat_comp{
    width: 50%;
    float: right;
  }

  .flight_search_details_row_test{
    width: 100%;
    display: flex;
  }

  .conditional_comp{
    width: 50%;
  }

  .returns_div{
    width: 100%;
    display: flex;
  }

  .conditional_comp{
    float: left;
  }

  .flight_search_details_row_multicity{
    display: flex;
    justify-content: flex-end;
  }


  .flight_search_details_row_multicity .flight_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 200px;
    height: 60px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}


.flightsearchpage_widget_flex_multicity{
  display: flex;
  width: 50%;
  gap: 10px;
}

.flightautocomplete_main_multicity{
  width: 30%;
  margin-right: 35px;
  display: flex;
  border-bottom: 1px #dcd8d8 solid;
  position: relative;
  }

  .flightautocomplete_main_multicity .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding: 0 0 10px 1px;
    width: 100%;
    height: 30px;
    background: none;
  }

  
.flightautocompletecalender_multicity{
  width: 25%;
  margin-right: 35px;
  border-bottom: 1px #dcd8d8 solid;
  display: flex;
  position: relative;
}


.flightautocompletecalender_multicity input{
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  border: none;
  padding: 0px 0px 10px 1px;
  width: 100%;
  height: 30px;
  background: none; 
  cursor: pointer;
}

.flightautocompletecalender_multicity .calendar_wid_main span{
  display: block;
  margin-right: 10px;
  margin-top: -10px;
  cursor: pointer;
}

.flightautocompletecalender_multicity .calendar_wid_main{
  margin-top: 8px;
}

.label_text label{
  color: #000;
  font-weight: 700;
}

.label_text span{
  padding: 0 !important;
  font-weight: 400 !important;
}


.flight_search_pax_multicity_modify{
  width: 23%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-left: 0 !important;
  border-bottom: 1px solid #dcd8d8;
  height: 100%;
}

.flightsearchpage_widget_multicity{
  padding: 0px 0px 10px 0px;
  margin: 0px 0px 10px 0px;
  display: flex;
}

.flight_search_pax_multicity_modify span{
  padding: 0 25px;
  font-weight: 700;
  stroke: .3px black;
}

.flight_search_pax_multicity_modify .minus_pax, .add_pax{
  color: grey !important;
}

.flight_search_class_modify_multicity{
  width: 25%;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcd8d8;
  position: relative;
}

.flightsearchpage_widget_flex_multicity_non_rep{
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
  height: 60px;
}

.flightsearchpage_widget_flex_multicity_non_rep .flightautocomplemodify{
  width: 30%;
}

.modifySearchWidget_divider{
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flightsearchpage_widget_flex_multicity_modify{
  display: flex;
  width: 100%;
  gap: 10px;
}

.remove_flight_button{
  position: absolute;
  right: 0;
}

.flight_adj_flex{
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 15px;
}
.fare_mainBox{
  float: left;
  margin-right: 15px;
  margin-bottom: 3px;
  position: relative;
  height: 70px;
  border-bottom: 2px solid #dedede;
  padding-top: 10px;
  background: #ffff;
  border-radius: 5px;
  padding: 5px 10px;
}
.fare_container{
  display: flex;
  clear: both;
}
.icon_box_advInputNw{
  margin-top: 5px;
}
.label_fare label{
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}