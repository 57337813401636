.hotel_search_wid_container{
  width: 100%;
  /* padding: 30px 25px 50px 30px; */
  padding: 20px;
  display: table;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,.15);
  margin-top: 0px;
  position: relative;
}
.hotel_way_title{
  color: var(--font_color);
  position: absolute;
  top: -50px;
  left: 0;
  padding: 15px 40px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
  gap: 5px;
}
.hotelNationBtob{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 15px; */
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dedede;
  position: relative;
  padding-bottom: 15px;
}

.hotelNationBtob:has(.vt_country_select_container) .vt_country_select_container{
border: 1px solid var(--primary_color);
}
.hotelTrip{
display: flex;
/* justify-content: flex-end; */
align-items: center;
flex-wrap: wrap;
/* padding-bottom: 15px; */
/* border-bottom: 1px solid #dedede; */
}
.hotel_way_title svg{
  position: relative;
  bottom: 8px;
}
.red_text {
font-size: 11px;
color: #e8664e;
line-height: 13px;
margin-top: 10px;
}

.hotel_way_title h2{
  font-family: var(--font_family);
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.55px;
  text-align: left;
  color: var(--primary_color);
}
.hotel_search_details_container{
  width: 100%;
  /* background: #e2e2e2; */
  margin-top: 15px;
}
.hotel_search_details_main{
  display: flex;
  align-items: center;
}
.hotel_search_details_container .hotel_search_details_main .hotel_search_details_row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.hotel_search_details_container .hotel_search_details_main .hotel_search_details_row .hotel_search_column{
  float: left;
  margin-right: 3px;
  margin-bottom: 3px;
  position: relative;
  background: #ffff;
  border-radius: 3px;
  height: 60px;
  cursor: default;
  border-radius: 0;
  border-bottom: 2px solid #dedede;
}
.hotel_search_wid_inputfield{
  align-items: center;
  line-height: normal;
  letter-spacing: normal;  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main{
  display: flex;
     /* flex-wrap: wrap; */
     width: 100%;
     align-items: flex-end;
     margin-top: 7px;
}
/* .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main input{
  margin-top: 10px;
} */
.hotel_search_details_main .hotel_search_details_row .hotel_search_name{
  width: 30%;
  padding-left: 0;
  margin-left: 0;
}
.hotel_search_details_main .hotel_search_details_row .hotel_search_date{
  width: 28%;
}
.hotel_search_details_main .hotel_search_details_row .hotel_search_accomodation{
  width: 25%;
}
.hotel_search_widget_btn:hover, .hotel_search_widget_btn:focus{
  /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
box-shadow: 1px 1px 10px var(--buttons_color);
  transition: transform .3s ease;
}
.hotel_search_details_row .hotel_search_widget_btn{
  background-color: var(--buttons_color);
  border: 1px solid var(--buttons_color);
  border-radius: 40px;
  padding: 8px 18px;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: .4px;
  text-align: center;
  margin-left: 0;
  color: #fff;
  cursor: pointer;
  outline: 0;
}
.hotel_search_details_row .hotel_search_class{
  margin-left: auto;
  margin-left: 5px;
  order: 2;
  margin-right: 3px;
  padding-left: 0;
  width: 22%;
  padding-top: 0 !important;
}
/* .hotel_search_details_row .hotel_search_class_btn{
  margin-left: auto;
  order: 2;
  margin-right: 3px;
  padding-left: 0;
  width: 22%;
} */
.hotel_search_accomodation .hotel_cabin_class_cont{
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  /* padding-top: 7px; */
}

.hotel_search_column .hotel_room_list{
  position: absolute;
  width: 370px;
  top: 60px;
  left: 0;
  background-color: #ffffff;
  right: 0px;
  z-index: 9;
  box-sizing: border-box;
  max-height: 400px;
  /* box-shadow: 0px 0px 3px 0px #aaa; */
  overflow: visible;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
}

.hotel_search_column .hotel_room_list .hotel_room_list_main{
  width: 360px;
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  border-top: none;
  box-shadow: 0 0 18px 2px rgb(0 0 0 / 7%);
  /* border-radius: 15px; */
  border-radius: 5px 0 0 5px;
  max-height: 300px;
  overflow-y: scroll;
}

.hotel_search_column .hotel_room_list .hotel_room_list_main .hotel_room_detail_ele{
  text-align: left;
  /* padding: 10px;
  width: 100%; */
  margin: 10px 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eaeaea;
}

.hotel_room_detail_ele .hotel_room_title{
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--primary_color);
  margin-right: 10px;
  width: 50%;
  text-transform: uppercase;
}
.hotel_room_head{    
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
}

.hotel_room_remove{
  font-family: var(--font_family);
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: var(--secondary_color);
  border: 1px solid var(--secondary_color);
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  height: 24px;
}
.hotel_remove_disabled{
font-family: var(--font_family);
font-size: 10px;
font-weight: bold;
font-style: normal;
font-stretch: normal;
line-height: 1.43;
letter-spacing: 0.3px;
color: var(--secondary_color);
border: 1px solid var(--secondary_color);
padding: 4px 8px;
margin-right: 4px;
border-radius: 50px;
cursor: pointer;
outline: none;
height: 24px;
opacity: 0.5;
}
.hotel_search_details_container .hotel_search_details_row .hotel_search_column .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .hotel_search_error_message{
  position: relative;
  top: 2px;
  float: left;
  width: 100%;
}
.hotel_search_details_container .hotel_search_details_row .hotel_search_column .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .hotel_search_error_message span{
  font-size: 11px;
  color: #e8664e;
  line-height: 13px;
  margin-top: 10px;
}
/* .hotel_accomodation_main >div{
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
} */
.hotel_accomodation_main >div img{
  position: relative;
  top: unset;
  left: unset;
}
.hotel_colleague_error_message{
  font-size: 11px;
  color: #e8664e;
  margin-top: 0;
  text-align: right;
}

.hotel_accomodation_conf{
  display: flex;
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(204, 204, 204, 0.46); */
  width: max-content;
  padding: 4px 5px;
  border-radius: 20px;
  float: right;
}

.hotel_accomodation_conf div{
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #000;
  width: 35px;
  text-align: center;
}
.hotel_accomodation_conf svg{
  position: initial !important;
}
.hotel_accomodation_main span{
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
}

.hotel_accomodation_main p{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  margin: 0px 0px;
  display: block;
}
.hotel_room_detail_ele .hotel_room_title span{
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--font_color);
  margin-right: 10px;
  width: 50%;
}

.hotel_room_list_main .hotel_room_detail_ele .hotel_accomodation_main{
  /* display: block; */
  margin-top: 0px;
  position: relative;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
  width: 100%;
  margin: auto;
  /* padding: 10px 0; */
  padding-bottom: 10px;
  display: none;
}

.hotel_accomodation_conf .hotel_accomodation_icon svg{
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  cursor: pointer;
  /* background-color: snow;
  border: 0; */
  border: 1px solid var(--secondary_color);
  border-radius: 50px;
  position: relative;
  top: unset;
  left: unset;
}

.age_list_ele{
  cursor: pointer;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  /* margin: 0px 0px 10px 0px; */
  /* padding: 5px 15px; */
  margin: 5px 15px;
  padding: 3px 15px;
  text-align: center;
  border-radius: 50px;
}
.age_list_ele:hover{
  background-color: var(--primary_color);
  color: #fff;
}
.disbale_traveller{
  pointer-events: none !important;
  opacity: 0.5 !important;
  /* background: #3c3c3c29 !important; */
  border-radius: 50px;
}

.disbale_traveller img{
  opacity: 0.5;
}

.done_button_main{
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background: #fff;
  border-radius: 15px;
  /* padding-bottom: 30px; */
}

.done_button_main .done_button{
  background-color: var(--buttons_color);
  border: none;
  color: #ffffff;
  padding: 22px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  cursor: pointer;
  width: 150px;
  text-transform: uppercase;
  margin: auto;
  border-radius: 10px;
}

.hotel_search_details_row label{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  display: block;
  width: 100%;
  /* padding-bottom: 0px; */
}

.hotel_search_details_row >div{
padding-left: 0;
  padding-top: 3px;
  margin-left: 5px;
  /* margin: 0 !important;
  margin-right: 20px !important; */
}

.hotel_search_details_row svg{
  /* position: absolute;
  left: 10px;
  top: 22px; */
  width: 25px;
  height: 25px;
  color: var(--primary_color);
  cursor: pointer;
}

.hotel_search_details_row input,.hotel_cabin_class_name
{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  border: 0;
  padding: 0 10px 0 0;
  height: 30px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none;
  outline: none;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: pointer;
  border: none;
  background: white;
}

.hotel_search_details_row input::placeholder{
  font-size: 15px;
  color: var(--primary_color);
  font-weight: normal;
  margin-top: 10px;
}

.hotel_search_details_row input{
  margin-top: 0px;
}
.hotel_search_details_row input:focus{
box-shadow: none;
border: none;
}

.hotel_autocomplete_value{
  position: absolute;
  width: 100%;
  /* max-height: 450px; */
  max-height: 350px;
  z-index: 9;
  top: 60px;
  background-color: #fff;
  /* box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%); */
  overflow: auto;
  color: var(--primary_color);
  font-size: 12px;
  left: 0;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  /* min-width: 530px; */
  min-width: 100%;
}
.hotel_autocomplete_value p {
  font-size: 14px;
  color: var(--font_color);
  padding: 10px;
}
  .hotel_autocomplete_element{
          width: 100%;
          display: flex;
          padding:5px 15px;  
          cursor: pointer;      
          align-items: center;
          /* min-height: 60px; */
          /* margin: 0px 15px; */
          width: auto;
          margin-top: 5px;
          border-radius: 50px;
   
  }

  .hotel_autocomplete_element:hover,
  .hotel_autocomplete_element:focus{
      background: var(--primary_color);
  }

  .hotel_autocomplete_element:hover .hotel_autocomplete_name,
  .hotel_autocomplete_element:focus .hotel_autocomplete_name{
     color:#fff
  }

  .hotel_autocomplete_element:hover .hotel_autocomplete_tag,
  .hotel_autocomplete_element:focus .hotel_autocomplete_tag{
     color:#fff
  }



.hotel_autocomplete_name{
  width: 100%;
  text-align: left;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  display: flex;
  justify-content: space-between;
}
.border_hrBottom{
  /* border-bottom: 1px solid #eaeaea; */
  margin: 0 15px;
  margin-top: 10px;
}
.hotel_autocomplete_element img{
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}
.hotel_autocomplete_tag{
  width: 100%;
  margin-top: 5px;
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary_color);
  text-align: left;
  display: block;
}


.hotel_search_details_row ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.hotel_search_details_row ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
}

/* Handle */
.hotel_search_details_row ::-webkit-scrollbar-thumb {
  background: var(--primary_color);
  border-radius: 10px;
}


.hotel_autocomplete_element img{
    position: relative;
    left: unset;
    top: unset;
    height: 23px;
    width: 23px;
    object-fit: scale-down;
    margin-right: 10px;
}


.hotel_add_rooms {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  color: var(--secondary_color);
  display: flex;
  justify-content: right;
  padding: 0px 10px;
  padding-right: 35px;
  cursor: pointer;
  /* width: 50%;
  margin: auto; */
}

.hotel_add_rooms span{

  width: 23px;
  height: 23px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 23px;
  margin: -1px 2px 0 0;
  font-weight: normal;
}

.child_row_main label{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  margin: 15px 0px 10px 0px;
  width: 100%;
  display: block;
}

.child_row_main input{
  font-family: var(--font_family);
  font-size: 12px;
  margin: 0 0 10px;
  height: 32px;
  border-bottom: 1px solid #dcd8d8;
  background-position: 97% 10px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  padding-left: 10px;
}

.child_row_main input::placeholder{
  font-family: var(--font_family);
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
}

/* ==================== Calendar ==================== */

.calendar_wid_container{
  width: 100%;
}
.calendar_wid_container .calendar_wid_main{
  align-items: center;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  margin-top: 7px;
  padding-top: 0px;
}
/* .calendar_wid_main svg{
  top: 22px;
} */
.calendar_wid_container .calendar_container{
  position: absolute;
  width: 612px;
  margin-top: 0px;
  top: 60px;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 9;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--primary_color);
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0/9%);
  border: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 0px 0px;

}

.calendar_wid_container .calendar_container.left{
  left: 0 !important;
}

.calendar_container .calendar_main{
  padding: 0px 0px;
  display: flex;
  flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
position: absolute;
cursor: pointer;
left: 25px;
top: 2px;
}
.calendar_container .calendar_main .calendar_head_right_side{
  position: absolute;
  cursor: pointer;
  right: 25px;
  top: 2px;
}

.calendar_main .calendar_whole_body{
  width: 100%;
  box-sizing: content-box;
}
.calendar_main .calendar_head{
  display: flex;
  width: 100%;
}
.calendar_head .calendar_head_left_side{
  text-align: left;
  width: 10%;
}
.calendar_head .calendar_head_center_side{
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 15px;
  width: 100%;
  /* border-bottom: 1px solid #ddd; */
  background: var(--primary_color);
  padding-top: 15px;
}

.calendar_head .calendar_head_right_side{
  text-align: right;
  width: 10%;
}

.calendar_head .calendar_head_icon{
  position: relative;
  left: 0;
  top: 0;    
}

.calendar_body .calendar_week_heads{
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
  padding: 5px 0;
  font-size: 10px;
  width: calc(100% / 7);
  text-align: center;
  font-family: var(--font_family);
  text-transform: uppercase;
  color: var(--font_color);
}
.calendar_body .calendar_day_list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
  text-align: center;
  text-decoration: none;
  border-radius: 7px;
  transition: color 0.3s, border 0.3s;
  cursor: pointer;
  height: 35px;
  font-family: var(--font_family);
  margin:  3px 0;
  font-size: 14px;
  width: calc(100% / 7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #dbdbdc; */
  /* margin: 2px; */
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
  text-align: center;
  text-decoration: none;
  transition: color 0.3s, border 0.3s;
  cursor: pointer;
  height: 35px;
  font-family: var(--font_family);
  margin:  3px 0;
  font-size: 14px;
  width: calc(100% / 7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #cbe3f5; */
  background-color: var(--primary_color);
  opacity: 0.5;
  color: #fff;
  border-radius: 50px;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.calendar_body{
  padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
  cursor: not-allowed;
  color: #999 !important;
  background-color: transparent !important;
  opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
  /* border-radius: 5px; */
  color: #fff;
  background-color: var(--primary_color);
  border-radius: 50px;
}

.calendar_head_icon svg{
  position: relative !important;
  top: unset !important;
  right: unset !important;
  color: #fff !important;
  top: 7px !important;
  width: 30px;
  height: 30px;
}

.child_row_main{
  position: relative;
  /* width: 25%; */
  width: 30%;
}
.child_age_select_arrow svg{
  height: 12px;
  width: 12px;
  position: relative;
  left: unset;
  top: unset;
  color: var(--secondary_color);
  fill: var(--font_color);
  right: 2px;
  top: 2px;
}

.child_row_main .child_age_select_arrow{
position: absolute;
right: 5px;
top: 10px;

}
.container_tooltip{
  position: absolute;
  margin-top: -60px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
  box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
  white-space: nowrap;
  font-size: 11px;
  pointer-events: none;
  z-index: 99;
  color: #000;
}
.container_tooltip:before {
position: absolute;
bottom: -5px;
left: calc(50% - 5px);
border-top: 5px solid rgba(0, 0, 0, 0.12);
border-right: 5px solid transparent;
border-left: 5px solid transparent;
content: ""
}
.container_tooltip:after {
position: absolute;
bottom: -4px;
left: calc(50% - 4px);
border-top: 4px solid #fff;
border-right: 4px solid transparent;
border-left: 4px solid transparent;
content: ""
}
.child_row_container{
  display: flex;
  /* justify-content: space-between; */
  gap: 3%;
  align-items: center;
  flex-wrap: wrap;
  /* width: 72%; */
  width: 100%;
  justify-content: flex-end;
  margin-left: auto;
}

.child_row_container .child_row_main .err_message_text{
font-size: 11px;
color: #e8664e;
line-height: 13px;
margin-top: 10px;
position: absolute;
left: -25px;
white-space: nowrap;
top: 88px;
}
.hotel_search_left_icon {
  cursor: pointer;
}
.hotel_search_left_icon span svg path, .flight_search_wid_inputfield_img svg path, .hotel_cabin_class_icon svg path{
  fill: var(--primary_color);
}
.flight_search_wid_inputfield_img{
  cursor: pointer;
  position: relative;
  top: 3px;
}
.hotel_cabin_class_name input{
  background-color: #ffffff;
  margin-top: 2px;
}

.adv_search_wrap{
  text-align: end;
  display: flex;
  margin-left: auto;
  width: 50%;
}
.collapsible_title{
  width: 50%;
}

.filter_container{
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.adv_text {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.check_advance_labels{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

.nationality_wrap{
  position: relative;
  display: inline;
}
.hotel_search_details_row .input_nationality_wd {
  width: 250px !important; 
  height: 40px !important;
  padding: 12px 20px 12px 12px !important;
  border-radius: 3px;
  border: solid 1px var(--primary_color) !important;
  background-color: #e2e0e0;
  position: relative;
  margin-top: 3px;
}
.pg_down_arrow i{
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.hotel_search_details_row .inputsearch_result{
  width: 250px;
  height: 383px;
  padding: 16px 22px 20px 20px;
  margin-top: 0px;
  border: solid 1px #ccc !important;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.hotel_search_details_row .inputsearch_result input{
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #ccc !important;
  background-color: #fff;
  cursor: auto;
  font-size: 14px;
  font-weight: 500;
  caret-color: auto;
}

.hotel_search_details_row_mg {
  /* margin-left: auto;
  padding-left: 0; */
  width: 22%;
}
.hotel_search_details_row_mg .hotel_search_widget_btn{
  background-color: var(--buttons_color);
  border: 1px solid var(--buttons_color);
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 3px;
  margin-left: 0px;
  color: #ffffff;
  cursor: pointer;
  outline: 0;
}

.hotel_search_btnClass{
  /* margin-left: 5px !important; */
  margin-left: auto;
  order: 2;
  margin-right: 3px;
  padding-left: 0 !important;
  /* width: 100%; */
  padding-top: 0 !important;
  margin-top: 12px;
}
.nationality_cnt_wd{
  margin-left: 44.50% !important;
}
.hotel_search_left_icon span img{
  position: absolute;
  top: 15px;
  left: 7px;
  width: 30px;
  height: 30px;
}

.hotel_search_details_row_button{
  /* width: 19%!important; */
  width: 100px !important;
  padding-left: 0 !important;
  /* padding-top: 25px !important; */
  position: relative;
  /* right: 7%; */
  margin-bottom: 3px;
  /* position: absolute !important;
  right: 32px;
  bottom: -19px; */
}

.hotel_search_btnClass svg {
  position: absolute!important;
  left: unset!important;
  right: 15px;
  width: 25px!important;
  height: 25px!important;
  top: 15px!important;
}
.search_widget_img_flex {
  /* display: flex;
  flex-wrap: nowrap; */
  width: 100%;
}
.search_widget_img_flex img{
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
/* .search_widget_img_flex svg{
  margin-right: 10px;
} */
.search_widget_img_flex input{
  margin-top: 0px;
}
.hotel_adult_accomodation_tag{
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0px 0;
  padding-top: 0;
  font-family: var(--font_family);
}
.hotel_adult_accomodation_tag p{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
  margin: 0px 0px;
  display: block;
}
.hotel_children_accomodation_tag{
  display: flex;
  align-items: center;
  gap: 3px;
  /* padding: 10px 0; */
  padding-bottom: 10px;
  font-family: var(--font_family);
}

.age_list_main{
  position: absolute;
  background: white;
  width: 100%;
  border: 1px solid #e0e0e0;
  height: 150px;
  overflow-y: scroll;
  z-index: 9999;
  top: 35px;
}

.hotel_search_details_row .hotel_arrow_pax{
  position: absolute;
  top: -16px;
  left: 80%;
}

.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .search_widget_img_flex input {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  padding: 0px;
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  display: inline-block;
  /* position: relative;
  bottom: 11px;
  height: 25px; */
  background: transparent;
  text-overflow: ellipsis;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .search_widget_img_flex input:focus {
border: none;
box-shadow: none;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .search_widget_img_flex input::placeholder {
  font-weight: 600;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .search_widget_img_flex h1{
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  border: 0 !important;
  padding: 0 10px 0 0;
  height: 30px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none !important;
  outline: none !important;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: pointer;
  caret-color: transparent;
  border: none;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .search_widget_img_flex h1{
  display: none;
}
.hotel_search_details_row .search_section_arrow .hotel_arrow_pax {
  position: absolute;
  top: 70px;
  left: 4%;
  background: transparent;
  z-index: 9;
}
.table_subhead{
  font-size: 10px;
}
.table_head, .table_body{
  width: 100%;
}
.table_head thead tr th{
  padding: 10px;
  background: var(--primary_color);
  color: #fff;
  text-align: center;
}
.table_body tbody tr td{
  padding: 10px;
  text-align: center;
}

.nationlity {
  width: 100%;
}
/* .traveller_button{
    background-color: var(--primary_color);
    width: 20px;
    border-radius: 5px;
    color: #fff;
} */
.traveller_button {
  width: 40px;
  padding: 1px 10px 1px;
  border-radius: 5px;
  background-color: var(--primary_color);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.guesttext {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: left;
  color: var(--font_color);
}
.dropdown_Guest_container {
  width: 600px;
  /* width: auto; */
  min-height: 204px;
  margin-top: 10px;
  padding: 15px;
  border: solid 2px #ccc;
  border-radius: 5px;
  background-color: #e2e2e2;
  position: absolute;
  right: 0;
  margin-left: auto;
  z-index: 9;
}
.inputsearch input {
  width: 100%;
  height: 36px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.adv_search_container,
.addguestbtn {
  position: relative;
}
.adv_search_wrap {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  /* width: 50%; */
}
.collapsible_title {
  /* width: 59%; */
  text-align: right;
  margin-right: 20px;
  margin-left: auto;
}
.advmodal_dropcontent {
  right: 0;
  z-index: 1;
  border-radius: 5px;
  padding: 15px 35px;
  margin-top: 10px;
  /* box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2); */
  border: 2px solid #ccc;
  width: 80%;
  padding-bottom: 25px;
  margin-left: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
.filter_container {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.adv_text {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.label_fname label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.label_fname {
  margin: 15px 0;
}
.input_fname {
  position: relative;
}
.input_fname span {
  position: absolute;
  top: 10px;
  right: 15px;
}
.mystyletext {
  padding-left: 20px;
}
.check_advance_labels {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

.nationality_wrap {
  position: relative;
  display: inline;
}
.input_nationality_wd {
  width: 250px;
  height: 40px;
  padding: 12px 20px 12px 12px;
  border-radius: 3px;
  border: solid 1px var(--primary_color);
  background-color: #e2e0e0;
  position: relative;
}
.pg_down_arrow i {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.inputsearch_result {
  width: 250px;
  height: 383px;
  padding: 16px 22px 20px 20px;
  margin-top: 0px;
  border: solid 1px #ccc;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.inputsearch_result input {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.advance_search_card_container {
  position: relative;
}
.right_advsearch {
  margin-left: 30px;
}
.advs_input {
  position: relative;
  margin-top: 20px;
}
.advs_input span {
  position: absolute;
  top: 10px;
  right: 15px;
}
.advance_search_card {
  position: absolute;
  background: #e2e2e2;
  border-radius: 5px;
  right: 0px;
  /* top:100px */
  top: 78px;
  z-index: 9;
  /* box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%); */
  border: 2px solid #ccc;
  padding: 26px;
  display: flex;
  flex-wrap: wrap;
  /* width: 900px; */
  z-index: 15;
}
.form_checkbox_adv {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.form_checkbox_adv > div {
  padding-right: 10px;
}
.input_container {
  display: inline-flex;
  gap: 10px;
  margin-bottom: 10px;
}
.dropdownfilter {
  position: relative;
}
.dropdownfilter {
  position: relative;
  width: 40%;
  margin-left: auto;
}
.dropmeallign {
  display: grid;
  margin-left: 20px;
  border: 1px solid #000000;
  padding: 10px;
  height: 170px;
  overflow-y: scroll;
  width: 258px;
  background: #fff;
}
.flight_cabin_class_ele {
  font-family: var(--font_family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

/* Traveller search design */

.gest_container {
  text-align: left;
}
.travel_gest p {
  font-size: 15px;
  margin-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-weight: 500;
}
.travlr_scroll {
  max-height: 140px;
  overflow: auto;
  padding-right: 5px;
}
.travlr_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.travlr_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}
.travlr_btn {
  cursor: pointer;
  color: #fff;
  padding: 5px 12px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary_color);
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.travlr_scroll::-webkit-scrollbar {
  width: 6px;
}

.travlr_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.travlr_scroll::-webkit-scrollbar-thumb {
  background: #666;
}

.travlr_scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary_color);
}

.travlr_scroll::-webkit-scrollbar-thumb:hover {
  background: var(--primary_color);
  opacity: 0.8;
}

.main_adv_multicity {
}

.advanc_addition_tabs {
  width: 100%;
  display: flex;
  gap: 3px;
  padding: 20px 0px;
  padding-top: 25px;
    padding-bottom: 10px;
}

.advance_search_card_container_multicity {
  width: max-content;
  position: absolute;
  right: 35px;
  top: 21px;
}

.adv_multicity_imp {
  width: 100%;
}

.advance_add {
  /* position: absolute;
  right: 35px; */
}
.collapsible_title_add {
  /* width: 59%; */
  text-align: right;
  margin-right: 20px;
  margin-left: auto;
  /* position: absolute;
  right: 210px; */
}

.adv_departure_time {
  width: 185px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  justify-content: space-evenly;
  height: 60px;
  border-radius: 3px;
  position: relative;
}

.time_icon {
  width: 20px;
  font-weight: 600;
  color: var(--secondary_color);
  fill: var(--primary_color);
}

.text_arrow_box {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text_arrow_box label {
  color: #545454;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}

.text_arrow_box span {
  color: #000;
  font-weight: 600;
}
.text_arrow_box svg {
  color: var(--secondary_color);
  width: 20px;
}
.text_box {
  display: flex;
  flex-direction: column;
}

.cabin_selector {
  width: 185px;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  padding: 0 5px;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}

.selector_icon {
  width: 25%;
  height: 100%;
  color: #016582;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector_text {
  cursor: pointer;
  width: 75%;
}

.secondary_text {
  text-transform: uppercase;
  color: #525252;
  font-size: 13px;
}

.primary_text {
  color: #000;
  font-weight: 600;
}

.drop_arrow {
  font-size: 30px;
  padding-top: 5px;
}

.departure_time_drop {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}
.departure_time_drop li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.departure_time_drop li {
  color: var(--primary_color);
}

.rbd_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}

.rbd_dropdown li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.rbd_input {
  padding: 5px 10px;
  width: 140px;
}

.rbd_input::placeholder {
  font-size: 14px;
}

.airline_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  width: 100%;
  box-shadow: 2px 2px 5px #858585bf;
}

.airline_dropdown li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.supplier_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}

.supplier_dropdown li {
  list-style: none;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.supplier_dropdown li:hover {
  background: var(--primary_color);
  color: #fff;
}

.drop_arrow svg {
  color: var(--secondary_color);
  width: 20px;
}

.departure_main_box {
  width: 175px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.departure_main_box .label_fname {
  color: grey;
  margin: 0;
}
.departure_main_box .label_fname label {
  /* color: grey;
  text-transform: uppercase;
  padding-left: 5px; */
  font-family: var(--font_family);
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;
    text-transform: capitalize;
}

.multiSelectDepartureTimeDropdown #search_input::placeholder {
  display: none;
  /* color: red !important; */
}

.multiSelectContainer #search_input {
  height: 60px !important;
}

.imp_select_dep {
  /* background: lime; */
}

.imp_select_dep select{
  background: transparent;
  border: none;
  outline: none;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  border: none;
  padding: 0 10px 0 0;
  padding-left: 15px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}

.imp_select_dep .multiSelectContainer .searchWrapper input.searchBox {
  /* background: red !important; */
}


.adv_tabs_split{
  width: max-content;
  display: flex;
  background: transparent;
  /* border-radius: 3px; */
  height: 70px;
  border-bottom: 2px solid #dedede;
  margin-right: 15px;
  position: relative;
}

.adv_tabs_split input{
  border: none;
  /* padding-left: 30px !important;
    padding-right: 5px !important; */
    width: 100%;
    /* width: auto; */
}

.adv_tabs_split input[type="radio"]{
  border: 1px solid var(--primary_color);
}

.adv_tabs_split input::placeholder{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  border: none;
  padding: 0 10px 0 0;
  padding-left: 15px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}
.checkbox{
  border: 1px solid grey;
}

.icon_box_adv{
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon_box_adv svg{
  min-width: 22px;
  height: 100%;
  fill: var(--primary_color);
  position: absolute;
  bottom: -25px;
}
.icon_box_adv svg path{
  fill: var(--primary_color);
}
.flight_search_column {
  float: left;
  margin-right: 15px;
  margin-bottom: 3px;
  position: relative;
  background: #ffff;
  border-radius: 0;
  height: 70px;
  border-bottom: 2px solid #dedede;
}

.checkbox_label{
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  align-items: flex-start;
}
.checkbox_label .bagCheckbox{
  position: relative;
  margin-left: 0 !important;
}
.checkbox_label .bagCheckbox input{
  cursor: pointer;
    height: 20px;
    opacity: 0;
    position: absolute;
    width: 20px;
    z-index: 2;
}
.checkbox_label .bagBox{
  align-items: center;
    background: #fff;
    border: 1px solid #9b9b9b;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 0 !important;
}

.checkbox_label .bagCheck{
  border-color: #fff;
  -webkit-border-image: none;
  border-image: none;
  border-style: none solid solid none;
  border-width: medium 2px 2px medium;
  box-sizing: border-box;
  height: 11px;
  opacity: 0;
  position: relative;
  top: -1px;
  -webkit-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  width: 5px;
  z-index: 1;
  margin-left: 0;
}
.checkbox_label input[type=checkbox]:checked~.bagBox {
  background: var(--secondary_color);
  border: 0;
}
.checkbox_label .bagCheckbox input[type=checkbox]:checked~.bagBox .bagCheck {
  opacity: 1;
  transform: scale(1) rotate(45deg);
}
.advTitle{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .38px;
  text-align: left;
  color: var(--primary_color);
  margin-left: 10px;
}

.flight_Adv_wrap{
  margin-left: auto;
  display: flex;
  align-items: flex-end;

}
.flight_AdvBtn{
  background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 40px;
    width: 100%;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}

.search_button_section{
  color: var(--secondary_color);
  font-family: var(--font_family);
  font-weight: 600;
  font-size: 14px;
}
.multicity_main{
  /* text-align: right; */
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
.flight_Adv_wrapMU{
  padding: 0;
}
.flight_AdvBtnMU{
  background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 40px;
    width: auto;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}
.baggage_multicity{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.wd_5{
  width: 5% !important;
}
.wd_10{
  width: 10% !important;
}
.wd_15{
  width: 15% !important;
}
.wd_20{
  width: 20% !important;
}
.wd_25{
  width: 25% !important;
}
.wd_30{
  width: 30% !important;
}
.wd_35{
  width: 35% !important;
}
.wd_40{
  width: 40% !important;
}
.wd_100{
  width: 100% !important;
}




  .adv_main_bx {
    border-bottom: 2px solid #dedede;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-top: 0px;
    padding-left: 0;
    cursor: pointer;
  }

  .hotel_type_label {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
  }

  .icon_div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
  }
  .icon_div svg {
    position: absolute;
    left: -1px;
    top: 3px;
    width: 25px;
    height: 25px;
    fill: var(--primary_color);
    cursor: pointer;
  }
  .text_div {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    padding: 0px;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    display: inline-block;
    background: transparent;
    text-overflow: ellipsis;
  }

  .dropdown_icon_div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
  }
  .dropdown_icon_div svg{
    position: absolute;
  left: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  color: var(--primary_color);
  cursor: pointer;
  }
  .adv_flx{
    display: flex;
    align-items: center;
  }
  .adv_dropMaincontFlx{
    display: flex;
  }
  .adv_dropMainBox{
    width: 32%;
    margin-right: 20px;
  }
  

  .dropDownlistmain {
    position: absolute;
    border: 1px solid #e0e0e0;
    margin-top: -2px;
    border-radius: 0 0 5px 5px;
    width: 100%;
  }
  
  .dropDownlistmain ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .dropDownlistmain ul li {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  
  .dropDownlistmain ul li input[type="text"] {
    height: 37px;
    width: 100%;
    padding: 4px 15px;
    border: 1px solid 
    #d6d6d6;
    border-radius: 50px;
  }
  
  .dropDownlistmain ul li input[type="checkbox"] {
    margin-right: 4px;
  }
  
  .dropDownlistmain ul li span {
    margin-right: 4px;
  }
  .dropdown_value_container{
    max-height: 190px;
    overflow: auto;
  }









  .checkbox_label{
    display: flex !important;
    align-items: flex-end;
    cursor: pointer;
  }
  .checkbox_label .bagCheckbox{
    position: relative;
    margin-left: 0 !important;
  }
  .checkbox_label .bagCheckbox input{
    cursor: pointer;
      height: 20px;
      opacity: 0;
      position: absolute;
      width: 20px;
      z-index: 2;
  }
  .checkbox_label .bagBox{
    align-items: center;
      background: #fff;
      border: 1px solid var(--secondary_color);
      border-radius: 2px;
      box-sizing: border-box;
      display: inline-block;
      display: flex;
      justify-content: center;
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: 0 !important;
      border-radius: 5px;
  }
  
  .checkbox_label .bagCheck{
    border-color: #fff;
    -o-border-image: none;
    border-image: none;
    border-style: none solid solid none;
    border-width: medium 2px 2px medium;
    box-sizing: border-box;
    height: 11px;
    opacity: 0;
    position: relative;
    top: -2px;
    left: 2px;
    transform: scale(1) rotate(45deg);
    transition-delay: .1s;
    width: 5px;
    z-index: 1;
    margin-left: 0;
  }
  .checkbox_label input[type=checkbox]:checked~.bagBox {
    background: var(--secondary_color);
    border: 0;
  }
  .checkbox_label .bagCheckbox input[type=checkbox]:checked~.bagBox .bagCheck {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
  .advTitle{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .38px;
    text-align: center;
    color: var(--primary_color);
    margin-left: 4px;
  }



.dropdown_value_container::-webkit-scrollbar {
  width: 10px;
}

.dropdown_value_container::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
.dropdown_value_container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.dropdown_value_container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.dropMain_container{
  padding: 0;
  position: relative;
}

.hotel_guestFlx{
  display: flex;
  align-items: center;
  margin-top: 7px;
  width: 100%;
}
.hotel_guestFlx svg{
  height: 20px;
}
.hotel_cabin_class_nameNew{}
.search_auto_flx{
  display: flex;
  align-items: center;
}
.hotel_srchCont .search_auto_flx span span svg{
  height: 20px;
}
.par_div{
  width: 100%;
}
.hotelNationBtob>div+div>div:nth-child(1){
  margin-top: 0px;
  border: none;
  padding-bottom: 0px;
}
.hotelNationBtob>div+div>div>div>div>div>div:nth-child(1){
  margin-top: 0px;
  display: flex;
  align-items: center;
}
.hotelNationBtob>div>span:nth-child(1){
  margin-right: 20px;
  display: block;
}
.selected_age{
  outline: none;
}
.selected_age_err{
  border: 1px solid red !important;
}

.hotel_cabin_class_nameNew{
  width: 100%;
}

.hotelTrip input{
  margin-right: 10px;
}

.hotelTrip div{
  margin-right: 10px;
}
.hotelTrip div:first-child{
   color: #000;
}

.hotel_search_error_message {
  font-size: 11px;
  color: #e8664e;
  margin-top: 4px;
  text-align: left;
}
.cityname_flex{
  align-items: center;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  margin-top: 7px;
  padding-top: 0px;
}
.cityname_flex .hotel_search_left_icon svg {
  height: 20px;
  width: 20px;
}
.cityname_flex input::placeholder{
  font-size: 14px;
  font-weight: 600;
}

.cityname_flex :global(.theme4_vt_country_select_container__vp3GT) {
  margin-top: 0;
  padding: 0;
  height: auto;
  border: 0;
}

.cityname_flex :global(.theme4_vt_country_select_main_container__SWTdQ .theme4_vt_country_select_list_container__TlUZ9) {
  overflow-y: auto;
  top: auto;
  left: 0;
  border-radius: 5px !important;
}

.cityname_flex :global(.theme4_signup_cont__VspMU) {
  width: 100%;
}
.hotelNationBtob :global(.nationality_error){
  position: absolute;
  left: 0;
}

@media screen and (min-width: 320px) and (max-width: 768px){
  .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .search_widget_img_flex input{
    width: 250px;
  }
.mobile_header .mobile_visible  {
  display: block;
}
.table_body tbody tr td{
  padding: 10px 1px;

}
.hotelTrip{
  margin-top: 20px;
}
.guestmaindiv .guestpostion{
  right: 17px;
  top: -50px;
}
.mobile_header  {
  position: sticky;
  top: 0px;
  z-index: 9;
}

.mobo_tab_header {
display: flex;
height: 60px;
background: #fff;
align-items: center;
padding: 0 15px;
box-shadow: 0 0 8px 0 rgba(0,0,0,.4);
}
.mobile_visible .arrow_image {
width: 20px;
height: 24px;
margin-right: 10px;
}
.mobile_visible .search_filter_header {
font-family: var(--font_family);
font-size: 17px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: .75px;
text-align: left;
color: var(--primary_color);
}
.hotel_mobileWrap{
width: 100%;
background: #e7e9eb;
min-height: 100vh;
}

.hotel_search_wid_container{
width: auto;
background: #e7e9eb;
border-radius: 0;
padding: 10px;
display: block;
margin: 0;
box-shadow: none;
}

.hotel_search_wid_main{
padding: 15px;
background: #fff;
border-radius: 15px;
}
.hotelNationBtob{
flex-direction: column;
align-items: flex-start;
}
.hotelNationBtob>div>span:nth-child(1){
margin-right: 0px;
display: block;
}
.hotel_search_details_container .hotel_search_details_main .hotel_search_details_row{
flex-direction: column;
margin-left: 0;
}
.hotel_autocomplete_value{
  top: 65px;
}
.hotel_search_details_container .hotel_search_details_main .hotel_search_details_row .hotel_search_column{
width: 100%;
margin: 0;
border: 0;
background: #fff;
border-radius: 10px;
border: 1px solid #d4d4d4;
margin-bottom: 12px;
padding: 5px 10px 3px;
height: 67px;
margin-bottom: 30px;
}
.hotel_search_details_container .hotel_search_details_row .hotel_search_column .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .hotel_search_error_message span{
  display: block;
  margin-top: 12px;
}
.hotel_search_details_container .hotel_search_details_row .hotel_search_column .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .hotel_search_error_message{
top: 0;
margin-top: 0;
}
.adv_dropMaincontFlx{
flex-direction: column;
}
.adv_dropMainBox{
width: 100%;
margin: 0;
}
.advmodal_dropcontent{
width: 100%;
margin-top: 0;
}
.adv_dropMainBox{
width: 100%;
border: 0;
background: #fff;
border-radius: 10px;
border: 1px solid #d4d4d4;
margin-bottom: 12px;
padding: 5px 10px 3px;
height: auto;
}
.adv_main_bx{
border-bottom: 0;
padding: 0;
padding-right: 10px;
align-items: flex-start;
margin-top: 7px;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main,
.calendar_wid_container .calendar_wid_main {
margin-top: 7px;
}
.icon_div{
margin-right: 0;
}
.hotel_search_btnClass{
width: 100%;
margin-left: 0 !important;
margin-right: 0;
margin-top: 0;
}
.hotel_search_details_row_button{
width: 100% !important;
}
.dropDownlistmain{
z-index: 9;
width: 106%;
left: -10px;
}
.calendar_wid_container{
width: 100%;
}

.calendar_wid_container_popup{
z-index: 100;
position: fixed;
min-width: 333px;
height: 100%;
background: white;
overflow: auto;
left: 0;
top: 0;
right: 0;
bottom: 0;
}
.calendar_head_main span {
display: block;
}
.calendar_container .calendar_main {
padding: 10px 15px;
display: flex;
flex-wrap: nowrap;
}
.calendar_main .calendar_head {
display: flex;
width: 100%;
}
.calendar_body {
padding-bottom: 10px;
margin-bottom: 20px;
border-bottom: 2px dashed #ccc;
}
.calendar_head_container{
width: 100%;
top: 0;
position: sticky;
left: 0px;
z-index: 2;
background-color: rgb(255, 255, 255);
}
.calendar_head_container .calendar_head_main{
width: 100%;
display: flex;
color: var(--font_color);
padding: 20px;
border-bottom: 1px solid #ddd;
font-size: 14px;
}
.calendar_head_container .calendar_head_main .calendar_head_left{
width: 50%;
text-align: center;
font-size: small;
align-items: center;
}
.calendar_head_container .calendar_head_main .calendar_head_right{
width: 50%;
text-align: center;
font-size: small;
align-items: center;
}
.calendar_modal_close {
height: 20px;
position: relative;
}
.calendar_modal_close svg {
height: 25px;
width: 25px;
background-color: var(--primary_color);
color: #fff;
border-radius: 50px;
padding: 4px;
z-index: 3;
margin-left: auto;
margin-top: 10px;
margin-right: 10px;
position: fixed;
right: 0;
}
.calendar_head .calendar_head_center_side{
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.35px;
text-align: left;
text-transform: uppercase;
color: var(--font_color);
width: 100%;
background-color: transparent;
}
.calendar_wid_container .calendar_main_body_popup{
overflow: auto;
/* height: 75vh; */
padding:  20px 30px 70px;
width: 100%;
display: block;
}
.calendar_footer{
position: fixed;
bottom: 0;
left: 0;
right: 0;
z-index: 999;
background: #fff;
padding: 10px;
text-align: center;
box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
}
.calendar_footer button{
text-align: center;
color: rgb(255, 255, 255);
background-color: var(--primary_color);
border-radius: 3px;
border: none;
font-size: 14px;
padding: 10px 50px;
}
.table_head thead th {
width: 25%;
}

.hotel_search_column .hotel_room_list {
width: 100%;
}

.hotel_search_column .hotel_room_list .hotel_room_list_main {
overflow: unset !important;
overflow-y: scroll !important;
}
.child_row_main input::placeholder {
font-size: 9px;
}
.hotel_add_rooms{
padding-right: 10px;
padding-bottom: 10px;
}
.hotel_search_details_row .hotel_search_widget_btn{
  background-color: var(--buttons_color);
  border: 1px solid var(--buttons_color);
border-radius: 50px;
width: 100%;
height: 50px;
font-family: var(--font_family);
font-size: 20px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: .5px;
text-align: center;
margin-top: 0px;
margin-left: 0;
color: #fff;
cursor: pointer;
outline: 0;
position: relative;
}
.search_widSRp_flx{
display: flex;
align-items: center;
}
.hotel_search_details_row .hotel_search_class{
width: 100%;
margin-left: 0 !important;
margin-right: 0;
}
.hotel_search_details_row svg{
height: 20px;
}
.Offlinewidgethotel{
  padding: 0;
}
.Offlinewidgethotel .hotel_search_wid_container {
  background-color: transparent;
  padding-top: 0;
}


.Offlinewidgethotel .hotel_search_wid_main {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

}



/* Enquiry popup */
.enquiy_container{
  width: 100%;
  text-align: center;
  padding: 27px 0;
}
.send_success{
  text-align: center;
  margin-bottom: 35px;

}
.send_success img{
  width: auto;
  height: 51px;
  margin: 0 auto;
  margin-bottom: 18px;
}
.send_lbl{
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--primary_color);
}

.quote_lblTitle{
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--font_color);
}

.quote_success{
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--primary_color);
}
.enquire_wrap{
  margin-bottom: 23px;
}

.ok_button{
  padding: 0 28px;
  height: 37px;
  border-radius: 30px;
  background-color: var(--buttons_color);
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
}

.btn_container{
  margin-top: 28px;
}
/* Enquiry popup */
